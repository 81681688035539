class dynamicSmoothScroll {
	static get MODAL_INNER_SELECTOR() {
		return '.modal-layer__inner';
	}

	static get MODAL_LAYER_SELECTOR() {
		return '.modal-layer';
	}

	static get LAYER_WRAPPER_SELECTOR() {
		return '.nm-layer-wrapper';
	}

	static get LAYER_CONTENT_SELECTOR() {
		return '.nm-layer-inner';
	}

	static get AVERAGE_PAGE_SIZE() {
		return 4000; // px
	}

	static get AVERAGE_SIZE_SCROLL_DURATION() {
		return 660; // timingXXL every 4000px
	}

	/**
	 * scroll - scrolls smoothly to target
	 * @param {HTMLElement} targetElement_ - where to scroll to
	 * @param {HTMLElement} scrollContext_ - either the window or a DOM Element to scroll
	 * @param {Number} startingPosition_ - where the scroll shall start from
	 * @returns {void}
	 */
	static scroll(targetElement_, scrollContext_, startingPosition_) {
		let startTime = null;
		let animationPosition = 0;
		let currentTargetPosition = targetElement_.getBoundingClientRect().top;
		let isScrollingDown = currentTargetPosition >= startingPosition_; // !isScrollingDown == isScrollingUp

		const scrollingDuration = this.calculateAnimationDuration(
			startingPosition_,
			currentTargetPosition,
		);

		// eslint-disable-next-line max-statements
		const animateScroll = (timestamp) => {
			if (!startTime) {
				startTime = timestamp || new Date().getTime();
			} // get id of animation
			let progress = (timestamp - startTime) / scrollingDuration;
			let offset = this.getRequiredOffset();

			if (progress > 1) {
				// prevent animation to get stuck in case of JS timeout problems
				cancelAnimationFrame(startTime);
				scrollContext_.scrollTo(0, currentTargetPosition - offset);
				return;
			}

			let easeInPercentage = Math.abs(this.easeOutQuad(progress)).toFixed(
				2,
			);

			let updatedTargetPosition =
				this.calculateScrollDestination(targetElement_);

			if (updatedTargetPosition !== currentTargetPosition) {
				// usecase: the page becomes longer while scrolling (dom elements growing or fixed elements becoming static)
				currentTargetPosition = updatedTargetPosition;
			}

			animationPosition = isScrollingDown
				? startingPosition_ + currentTargetPosition * easeInPercentage
				: currentTargetPosition +
				  (startingPosition_ - currentTargetPosition) *
						(1 - easeInPercentage);

			scrollContext_.scrollTo(0, animationPosition);

			if (
				(isScrollingDown &&
					((currentTargetPosition !== 0 &&
						animationPosition >= currentTargetPosition - offset) ||
						(currentTargetPosition === 0 &&
							animationPosition < 0) ||
						updatedTargetPosition - startingPosition_ <= 300)) || // the page shortens
				(!isScrollingDown &&
					animationPosition < currentTargetPosition + offset)
			) {
				cancelAnimationFrame(startTime);
				animationPosition = 0;
				scrollContext_.scrollTo(0, currentTargetPosition - offset);
			} else {
				window.requestAnimationFrame(animateScroll);
			}
		};
		window.requestAnimationFrame(animateScroll);
	}

	/**
	 * getRequiredOffset - return the required offset to stop scrolling in order not
	 * to have footnotes hidden behind sticky elements
	 * @returns {Number} complete sticky offset to be considered
	 */
	static getRequiredOffset() {
		const defaultOffset = 120; // arbitrary value
		const stickyElementsIncreasedOffset = 30; // arbitrary value

		let requiredOffset =
			defaultOffset +
			this.getStickyNavigationOffset() +
			this.getStickyAnchorNavigationOffset();

		if (requiredOffset !== defaultOffset) {
			requiredOffset += stickyElementsIncreasedOffset;
		}

		return requiredOffset;
	}

	/**
	 * getStickyNavigationOffset - the height of the inpage navigation
	 * (optional: including the consumption module if present)
	 * @returns {Number} complete sticky offset to be considered
	 */
	static getStickyNavigationOffset() {
		let stickyNavigationOffset = 0;
		if (
			this.getScrollContext() === document.body &&
			document.body.classList.contains('nm-nav-fixed')
		) {
			const inpageNavigation = document.querySelector('.nm-nav-wrap');
			if (inpageNavigation) {
				stickyNavigationOffset = inpageNavigation.clientHeight;
			}

			if (
				document.body.classList.contains('nm-has-current-consumption')
			) {
				const currentConsumptionModule = document.querySelector(
					'.nm-module-current-consumption',
				);
				if (currentConsumptionModule) {
					stickyNavigationOffset +=
						currentConsumptionModule.clientHeight;
				}
			}
		}

		return stickyNavigationOffset;
	}

	/**
	 * getStickyAnchorNavigationOffset - return the required offset to stop scrolling in order not
	 * to have footnotes hidden behind sticky elements
	 * @returns {Number} complete sticky offset to be considered
	 */
	static getStickyAnchorNavigationOffset() {
		const anchorNavigationList = document.querySelector(
			'.nm-anchor-navigation-list',
		);
		let stickyAnchorNavigationOffset = 0;
		if (
			this.getScrollContext() === document.body &&
			!!anchorNavigationList
		) {
			const stickyAnchorAttribute =
				anchorNavigationList.getAttribute('is-sticky');
			if (stickyAnchorAttribute === 'true') {
				stickyAnchorNavigationOffset =
					anchorNavigationList.clientHeight;
			}
		}

		return stickyAnchorNavigationOffset;
	}

	/**
	 * easeOutQuad - quadratic function https://easings.net/de
	 * @param {Number} numericInput_ - the number to be exponentially decreased
	 * @returns {Number} numericOutput
	 */
	static easeOutQuad(numericInput_) {
		const numericOutput = numericInput_ * (2 - numericInput_);
		return numericOutput;
	}

	/**
	 * calculateAnimationDuration - distance based dynamic animation length
	 * @param {Number} startingPosition_ - where it begins
	 * @param {Number} targetPosition_ - where it ends
	 * @returns {Number} scrollingDuration - animation milliseconds
	 */
	static calculateAnimationDuration(startingPosition_, targetPosition_) {
		const delta = Math.abs(targetPosition_ - startingPosition_);
		const scrollingDuration =
			(dynamicSmoothScroll.AVERAGE_SIZE_SCROLL_DURATION /
				dynamicSmoothScroll.AVERAGE_PAGE_SIZE) *
			delta;

		return scrollingDuration;
	}

	/**
	 * calculateScrollDestination - calculates scrolling position
	 * @param {HTMLElement} target_ - target element to scroll to
	 * @returns {Number} scroll position
	 */
	static calculateScrollDestination(target_) {
		const context = this.getScrollContext();
		const bodyRect = context.getBoundingClientRect();
		const elemRect = target_.getBoundingClientRect();
		const layerCorrection =
			context === document.body ? 0 : context.scrollTop;

		return elemRect.top - bodyRect.top + layerCorrection;
	}

	static getScrollContext() {
		if (document.querySelector(this.MODAL_INNER_SELECTOR)) {
			return document.querySelector(this.MODAL_LAYER_SELECTOR);
		}

		if (document.querySelector(this.LAYER_CONTENT_SELECTOR)) {
			return document.querySelector(this.LAYER_WRAPPER_SELECTOR);
		}

		return document.body;
	}
}

export { dynamicSmoothScroll };
