/* eslint-disable max-len */
const exports =
	`
	{{? SETUPS.get('nemo.footnote-engine.active') === true}}
		{{? !SETUPS.get('scopes.nemo.DISABLE_FEATURE_HUB') && SETUPS.get('scopes.nemo.ONEFOOTER_FA_INCLUDE') && SETUPS.get('nemo.fefa.scOneFootnoteJsonUrl') && SETUPS.get('nemo.FefaFeatureAppVersion')}}
			<feature-app id="audi-footnote-engine-layer-` +
	Math.floor(Math.random() * 1000000) +
	`" {{? /^https?:/gi.test(SETUPS.get('nemo.FefaFeatureAppVersion')) }}
		src="{{=SETUPS.get('nemo.FefaFeatureAppVersion')}}"
	{{??}}
	src="https://oneaudi-fa-footnote-engine.cdn.prod.arcade.apps.one.audi/{{=SETUPS.get('nemo.FefaFeatureAppVersion')}}/fh/app.js"
	{{?}}
	 config='{"apiUrls": ["{{=SETUPS.get('nemo.fefa.scOneFootnoteJsonUrl')}}"], "omitSideSpacing": "true", "uiTheme": "light"}'></feature-app>
		{{??}}
			<audi-footnotes class="audi-footnotes sc-footnotes" data-module="footnotes">
				<ul class="audi-footnotes-list"></ul>
			</audi-footnotes>
		{{?}}
	{{?}}
`;
/* eslint-enable max-len */
export { exports as modalLayerFootnoteEngineTemplate };
