/**
 * URL-routable JS-driven layers.
 * The module name is pronounced *j-slayer*, not *js-layer*.
 * @exports core/layer/jslayer
 */

import { appEvents } from '../app-events';
import { dom } from '../../../js/utils-bundle';
import { api as layerApi } from './api';
import { router } from '../router';

const __ = {};

const exports = {
	__: __,
};

__.callbacks = {};
__.layer = null;

// Store keys from url if not yet registered (or found in DOM).
__.pending = null;

__.openLayersSinceLastClose = 0;

exports.initialize = function (eventEmitter) {
	__.eventBus = eventEmitter;
	__.registerAtRouter();
	__.registerEvents();
};

/**
 * Register a callback for a router event.
 * @param {string} idStr - The link element to listen to.
 * @param {Function} callback - The callback that is called to
 *    retrieve the layer content.
 * @returns {void} returns nothing
 */
exports.register = function (idStr, callback) {
	__.callbacks[idStr] = callback;
	// The idStr is currently pending from a deeplink, open it immediately.
	if (
		__.pending &&
		__.pending.length === 2 &&
		__.pending[0] &&
		idStr === __.pending[0]
	) {
		__.processLayer(idStr, __.pending[1]);
	}
};

/**
 * register event classes at router
 * @returns {void}
 */
__.registerAtRouter = function () {
	router.register('.nm-jslayerLink', 'jslayer');
	router.register('.nm-j-jslayer .nm-button-close', 'jslayer', 'close');
	router.register(
		'.nm-j-jslayer .nm-j-layer-close-button',
		'jslayer',
		'close',
	);
};

/**
 * register events
 * @returns {void}
 */
__.registerEvents = function () {
	__.eventBus.on('jslayer.open', __.handleJslayerOpen);
	__.eventBus.on('jslayer.close', __.handleJslayerClose);
	__.domDelegate = dom.getEventDelegate('body');
	__.domDelegate.on(
		'click',
		'.nm-j-jslayer .nm-button-back',
		__.handleBackClick,
	);
};

/**
 * Handles client.open events as triggered by the router module.
 * @param {HTMLElement} jslayerObj jslayerObj
 * @returns {void} returns nothing
 */
__.handleJslayerOpen = function (jslayerObj) {
	// eslint-disable-line max-statements
	let idStr =
		jslayerObj[0] && jslayerObj[0].jslayer ? jslayerObj[0].jslayer : null;
	let key;
	let id;
	let parts;

	if (!idStr) {
		return;
	}

	// Clean the idStr from # and full urls.
	if (idStr.indexOf('#') > -1) {
		idStr = idStr.split('#')[1];
	}

	// no prefix, key & id are the same.
	key = id = idStr;

	// prefix found.
	if (idStr.indexOf('.') > -1) {
		parts = idStr.split('.');
		key = parts[0];
		id = parts[1];
	}

	if (!!__.callbacks[key] && typeof __.callbacks[key] === 'function') {
		__.processLayer(key, id);
	} else {
		__.pending = [key, id];
	}
};

/*
 * Get the HTML from the callback and open a layer.
 */
__.processLayer = function (key, id) {
	const layerStr = __.callbacks[key].call({}, id);
	layerApi.open('jslayer', layerStr, __.handleCloseRequest).then(
		function (layer) {
			__.layer = layer;
			__.layer.key = key;
			__.layer.id = id;
			__.openLayersSinceLastClose += 1;

			if (__.openLayersSinceLastClose > 1) {
				__.layer.element.classList.add('nm-jslayer-show-backbutton');
			} else {
				__.layer.element.classList.remove('nm-jslayer-show-backbutton');
			}
		},
		function (err) {
			console.warn('jslayer.handleJslayerOpen:', err);
		},
	);
};

__.handleCloseRequest = function () {
	let url = __.layer.key;

	if (__.layer.id) {
		url = url + '.' + __.layer.id;
	}

	router.close('jslayer', url);
	__.openLayersSinceLastClose = 0;
};

/**
 * handleBackClick
 * handles click on jslayer back button
 * @return {void} returns nothing
 */
__.handleBackClick = function () {
	__.openLayersSinceLastClose -= 2;
	history.back();
};

/**
 * handle layer close event
 * @returns {void}
 */
__.handleJslayerClose = function () {
	if (__.layer && __.layer.close) {
		__.layer.close();

		__.eventBus.emit(appEvents.LAYER_CLOSE, {
			element: __.layer.element,
		});

		__.pending = null;
		__.openLayersSinceLastClose = 0;
	}
};

export { exports as client };
