const __ = {},
	exports = {
		__: __,
	};
// set defaults
__.oDefaults = {
	sType: 'sessionStorage',
};

/**
 * Normalizes the object passed to the API functions
 * @param {Object} oOptions_ raw options object as passed to the public API functions
 * @returns {Object} returns value
 */
__.normalizeOptions = function (oOptions_) {
	let oOptions = oOptions_ || {};
	// no type - use default
	if (typeof oOptions === 'object' && !oOptions.type) {
		oOptions.type = __.oDefaults.sType;
	}
	// handle String AND object
	return {
		failsafeSet: !!oOptions.failsafeSet,
		sType: typeof oOptions === 'string' ? oOptions : oOptions.type,
	};
};
/**
	 * Reads the stored values
	 * @private
	 * @param {string} namespace	The 'key' to store the data under
	 * @param {string} sKey_		Identifier to grab
	 * @param {string | Object} oOptions_	Indicates the type ('localStorage','sessionStorage')
	 * Object {
    		type: {string}, 'localStorage','sessionStorage'
    	}
	 * @return {Object} returns value
	 */
__.read = function (namespace, sKey_, oOptions_) {
	const _sType = __.normalizeOptions(oOptions_).sType;
	const data = JSON.parse(window[_sType].getItem(namespace));
	return data ? data[sKey_] : null;
	// return window[_sType].getItem(namespace)[sKey_];
};
/**
	 * Writes a value
	 * @private
	 * @param {string} namespace	The 'key' to store the data under
	 * @param {string} sKey_		Identifier to grab
	 * @param {Object} sValue_		Value to store
	 * @param {string | Object}	oOptions_ String: indicates the type ( 'localStorage','sessionStorage')
	 * Object
	 {
		 type: {string}, 'localStorage','sessionStorage',
	 }
	 * @return {void} returns nothing
	 */
__.write = function (namespace, sKey_, sValue_, oOptions_) {
	const _options = __.normalizeOptions(oOptions_);
	const _sType = _options.sType;

	try {
		let data = window[_sType].getItem(namespace);
		if (data === null) {
			data = {};
		} else {
			data = JSON.parse(data);
		}
		data[sKey_] = sValue_;
		data = JSON.stringify(data);
		window[_sType].setItem(namespace, data);
		return true;
	} catch (e) {
		if (_options.failsafeSet) {
			_options.failsafeSet = false;
			return __.write(namespace, sKey_, sValue_, _options);
		}
		return false;
	}
};
/**
	 * Clears the cache
	 * @private
	 * @param {string} namespace The 'key' to store the data under
	 * @param {string | Object} oOptions_	Indicates the type ( 'localStorage','sessionStorage')
	 * Object
	 {
		 type: {string}, 'localStorage','sessionStorage',
	 }
	 * @return {void} returns nothing
	 */
__.clear = function (namespace, oOptions_) {
	const _sType = __.normalizeOptions(oOptions_).sType;
	window[_sType].removeItem(namespace);
};
/**
	 * Returns the number of key-value pairs currently present.
	 * Pass the 'sType_' to distinguish between the different storages
	 * @private
	 * @param {string} namespace The 'key' to store the data under
	 * @param {string | Object} oOptions_ Indicates the type ( 'localStorage','sessionStorage' )
	 * Object
	 {
		 type: {string}, 'localStorage','sessionStorage'
	 }
	 * @returns {boolean} returns boolean
	 */
__.length = function (namespace, oOptions_) {
	const storageType = __.normalizeOptions(oOptions_).sType;
	const data = JSON.parse(window[storageType].getItem(namespace));
	let num = 0;
	if (Object.keys && !!data) {
		num = Object.keys(data).length;
	} else {
		for (let prop in data) {
			// eslint-disable-next-line no-prototype-builtins
			if (data.hasOwnProperty(prop)) {
				num = num + 1;
			}
		}
	}
	return num;
};
/**
 * Public API
 */

/**
 * API: Reads the stored values
 * @param {string} namespace	The 'key' to store the data under
 * @param {string} sKey_		Identifier to grab
 * @param {string | Object} oOptions_	Indicates the type ( 'localStorage','sessionStorage' )
 * Object
 {
	 type: {string}, 'localStorage','sessionStorage'
 }
 * @returns {Value} egwegerg
 */
exports.getItem = function (namespace, sKey_, oOptions_) {
	let sKey = encodeURIComponent(sKey_);
	return __.read(namespace, sKey, oOptions_);
};
/**
 * API: Writes a value
 * @param {string} namespace	The 'key' to store the data under
 * @param {string} sKey_		Identifier to grab
 * @param {Object} sValue_		Value to store
 * @param {Object|String} oOptions_|sType_, additional options (expires: session) or sType_
 * @return {void} returns nothing
 */
exports.setItem = function (namespace, sKey_, sValue_, oOptions_) {
	let sKey = encodeURIComponent(sKey_);
	return __.write(namespace, sKey, sValue_, oOptions_);
};
/**
 * API: Deletes a value
 * @param {string} namespace	The 'key' under the data is stored
 * @param {string} sKey_		Identifier to delete
 * @param {Object|string}		oOptions_|sType_, or sType_
 * @return {void}
 */
exports.removeItem = function (namespace, sKey_, oOptions_) {
	let sKey = encodeURIComponent(sKey_);
	__.write(namespace, sKey, null, oOptions_);
};
/**
 * API: Clears the chache for given 'namespace'
 * @param {string} namespace	The 'key' under the data is stored
 * @param {Object|string}		oOptions_|sType_, or sType_
 * @return {void}
 */
exports.clear = __.clear;
/**
 * API: Returns the number of key-value pairs currently present stored under 'namespace'
 * @param {string}		namespace	The 'key' under the data is stored
 * @param {string}		An additional key to store values under
 * @param {Object|string} oOptions_|sType_, or sType_
 * @return {Number}
 */
exports.length = __.length;

export { exports as cache };
