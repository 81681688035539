/**
 * The CMS layer controller. It does not expose a public API.
 * @exports core/layer/cms
 */
import { api } from './api';
import { appEvents } from '../app-events';
import { content } from '../content';
import { dom } from '../../utils/dom';
import { dynamicDot } from '../dot/dynamic-dot';
import { errorTemplate } from './error-template';
import { router } from '../router';
import { template } from '../../../js/utils-bundle';

const __ = {};
const exports = {
	__: __,
};

/*
 * DOM selectors and IDs.
 */
__.LAYER_TPL_ID = 'nm-id-default-data-layer-tpl';
__.defaultLayerType = 'cms';
// Storage for the template sting
__.templateStr = null;
__.layer = null;
// The data for the next layer;
// [data, attrs, replacedAttrs]
__.nextLayerData = null;
__.openLayersSinceLastClose = 0;
__.openLayersSinceLastTab = 0;
__.closeButtonHistoryBackTarget = -1;
__.backButtonEl = null;
__.scrollStackingIndex = 0;
__.stackingDirections = {
	// switch value-obj between 2 exclusively available options
	downwards: 'oneLessLayer',
	upwards: 'onMoreLayer',
};
__.layerStackingDirection = __.stackingDirections.upwards;
__.scrollPositionStackingArray = [];

__.debugActive = false; // enable/disable scrollPositionStacking console output

exports.initialize = function (eventEmitter) {
	__.eventBus = eventEmitter;
	__.domEventDelegate = dom.getEventDelegate('body');
	__.addEvents();
	__.registerAtRouter();
};

/*
 * Subscribes to layerOpen and layerClose
 */
__.addEvents = function () {
	__.eventBus.on(appEvents.LAYER_OPEN, __.handleLayerOpen);
	__.eventBus.on(appEvents.LAYER_CLOSE, __.handleLayerClose);
};

/**
 * register .nm-layerLink clicks
 * @returns {void}
 */
__.registerAtRouter = function () {
	router.register('.nm-layerLink', 'layer');
	router.register('.nm-layer .nm-button-close', 'layer', 'close');
	router.register('.nm-layer .nm-button-cancel', 'layer', 'close');
	router.register('.nm-layer .nm-j-layer-close-button', 'layer', 'close');
	router.register('.nm-layerClose', 'layer', 'close');
};

/**
 * Handles a layer.open event
 * @param {Object} data - The event payload.
 * @returns {void}
 */
__.handleLayerOpen = function (data) {
	if (!!this && typeof this.preventDefault === 'function') {
		this.preventDefault();
	}

	__.nextLayerData = [data[0].layer, data[1], data[2]];

	// open a layer within this layer
	if (data[1]['data-innerlayer']) {
		__.closeButtonHistoryBackTarget--;
		__.openLayersSinceLastTab = 0;
	}
	// open a layer regularly
	else {
		__.openLayersSinceLastClose += 1;
		__.openLayersSinceLastTab += 1;
		__.storeScrollStackingPosition();
	}

	__.startNewLayerProcess();
};

__.layerCloseHandler = function (e) {
	if (e.preventDefault) {
		e.preventDefault();
	}
	api.requestCloseall();
	__.domEventDelegate.off(
		'click',
		'.nm-error-layer .nm-j-ok-btn',
		__.layerCloseHandler,
	);
	__.scrollStackingIndex = 1; // jump out of the stack hierarchy
	__.layerStackingDirection = __.stackingDirections.downwards;
};

__.handleCloseRequest = function () {
	router.close('layer', __.layer.url);
	__.scrollStackingIndex = 1; // jump out of the stack hierarchy
	__.debugScrollStacking('closeRequest', __.scrollStackingIndex);
};

__.controlBackButton = function (layer) {
	const context = layer.getElement();
	let innerLayerCloseButton, realCloseButton;

	if (context) {
		realCloseButton = context.querySelector('.nm-button-close');
		innerLayerCloseButton = context.querySelector('.nm-innerlayer-close');

		if (!realCloseButton) {
			realCloseButton = context.querySelector('.nm-j-layer-close-button');
			innerLayerCloseButton = context.querySelector(
				'.nm-j-innerlayer-close-button',
			);
		}

		if (__.openLayersSinceLastClose > 1) {
			__.enableTabsCloseButton(realCloseButton, innerLayerCloseButton);
		} else {
			__.disableTabsCloseButton(realCloseButton, innerLayerCloseButton);
		}
	}
};

__.enableTabsCloseButton = function (realCloseButton, innerLayerCloseButton) {
	realCloseButton.style.display = 'none';
	if (innerLayerCloseButton) {
		innerLayerCloseButton.style.display = 'inline-flex';
		innerLayerCloseButton.addEventListener('click', __.handleLayerBack);
	}
};

__.disableTabsCloseButton = function (realCloseButton, innerLayerCloseButton) {
	realCloseButton.style.display = 'inline-flex';
	if (innerLayerCloseButton) {
		innerLayerCloseButton.style.display = 'none';
		innerLayerCloseButton.removeEventListener('click', __.handleLayerBack);
	}
};

__.handleLayerBack = function (event_) {
	let numberOfStepsBack;

	event_.stopPropagation();

	// omit all inner layers and return to last layer before inner layer navigation
	if (
		__.closeButtonHistoryBackTarget !== -1 &&
		__.openLayersSinceLastTab === 0
	) {
		numberOfStepsBack = __.closeButtonHistoryBackTarget;
		history.go(numberOfStepsBack);
	}
	// return to last layer
	else {
		history.back();
		__.openLayersSinceLastTab -= 1;
	}
	__.closeButtonHistoryBackTarget = -1;

	// special handling for layer open with inner layer navigation in between other layer openings
	if (__.openLayersSinceLastClose > 2) {
		__.openLayersSinceLastClose -= 1;
	} else {
		__.openLayersSinceLastClose -= 2;
	}
	__.layerStackingDirection = __.stackingDirections.downwards;
	__.debugScrollStacking('handleBack', __.scrollStackingIndex);
	__.checkCookieLayer();
};

/**
 * Handles a layer.close event
 * @param {Object} data - The event payload.
 * @returns {void}
 */
__.handleLayerClose = function () {
	if (!!this && typeof this.preventDefault === 'function') {
		this.preventDefault();
	}
	__.openLayersSinceLastClose = 0;
	__.openLayersSinceLastTab = 0;
	__.closeButtonHistoryBackTarget = -1;
	__.layerStackingDirection = __.stackingDirections.downwards;

	if (!__.layer) {
		return false;
	}
	__.layer.close().then(
		function () {
			// everything's fine.
			__.layer = undefined;
			__.startNewLayerProcess();
			__.setScrollStackingPosition();
			__.checkCookieLayer();
		},
		function (err) {
			console.error(err);
		},
	);
};

__.checkCookieLayer = function () {
	const body = document.querySelector('body');
	const isCookieBannerVisible = body.classList.contains(
		'nm-ensBanner-isVisible',
	);
	const isLegalLayerVisible = body.classList.contains(
		'nm-ensBanner-isVisible--showLegalLayers',
	);
	if (isLegalLayerVisible && isCookieBannerVisible) {
		body.classList.remove('nm-ensBanner-isVisible--showLegalLayers');
	}
};

/**
 * Start a new layer process (if none is currently being processed.)
 * @returns {void}
 */
__.startNewLayerProcess = function () {
	let url, attrs;

	// Abort if there is no new data.
	if (!__.nextLayerData) {
		return;
	}

	// store data and empty queue var.
	url = __.nextLayerData[0];
	attrs = __.nextLayerData[1];
	__.nextLayerData = null;

	const templatePromise = __.getTemplatePromise(attrs);
	const contentPromise = content.getContent(url, false);
	Promise.all([templatePromise, contentPromise])
		.then(function ([templateContent, content_]) {
			const isLayer = content_.indexOf('<html');
			if (isLayer !== -1) {
				__.handleError();
			}

			const layerContent = template.render(templateContent, {
				content: content_,
			});

			if (!__.layer || __.layer.type === 'cms') {
				return api
					.open('cms', layerContent, __.handleCloseRequest)
					.then(function (layer) {
						__.layer = layer;
						__.layer.url = url;
						__.controlBackButton(__.layer);
						__.setScrollStackingPosition();
					})
					.catch(function (err) {
						console.error(err);
					});
			} else {
				console.warn('could not Open Layer');
			}
		})
		.catch((error) => {
			console.error('An error occured', error);
			__.handleError(error);
		});
};

/**
 * saves the scroll position of the layer currently being left
 * in an array with the stackingIndex as index/key
 * @returns {void}
 */
__.storeScrollStackingPosition = function () {
	const layerWrapper = document.querySelector('.nm-layer-wrapper');
	const isDesktopView =
		!!layerWrapper && getComputedStyle(layerWrapper).position === 'fixed';
	const layerWrapperIsVisible = __.scrollStackingIndex >= 1;

	const scrollPositionToSave =
		isDesktopView && layerWrapperIsVisible
			? layerWrapper.scrollTop
			: window.pageYOffset || 0;

	if (__.layerStackingDirection === __.stackingDirections.upwards) {
		__.scrollPositionStackingArray[__.scrollStackingIndex] =
			scrollPositionToSave;

		__.scrollStackingIndex++;
	}
	__.debugScrollStacking('storePosition', __.scrollStackingIndex - 1);
};

/**
 * overwrites the scroll position of the layer currently being shown
 * with a previously saved offset
 * @returns {void}
 */
__.setScrollStackingPosition = function () {
	const layerWrapper = document.querySelector('.nm-layer-wrapper');
	const isDesktopView =
		!!layerWrapper && getComputedStyle(layerWrapper).position === 'fixed';
	const layerWrapperIsVisible = __.scrollStackingIndex >= 1;
	const manipulateLayer = isDesktopView && layerWrapperIsVisible;

	if (__.layerStackingDirection === __.stackingDirections.downwards) {
		__.scrollStackingIndex--;
		// set parent layer to stored target position
		__.scrollToStoredPosition(
			manipulateLayer,
			layerWrapper,
			__.scrollPositionStackingArray[__.scrollStackingIndex],
		);
		__.layerStackingDirection = __.stackingDirections.upwards;
	} else {
		// scroll new layer to top
		__.scrollToStoredPosition(manipulateLayer, layerWrapper, 0);
		__.scrollPositionStackingArray[__.scrollStackingIndex] = 0;
	}
	__.debugScrollStacking('setPosition', __.scrollStackingIndex);
};

/**
 * restores the correct scrollPosition for the proper DOM Element
 * depending on the actual viewport
 * @param {boolean} manipulateLayer_ - Either to Scroll the Window or the Layer-Position
 * @param {HTMLElement} layerWrapper_ - The Layer Element to manipulate
 * @param {number} targetPosition_ - The target scroll position
 * @returns {void}
 */
__.scrollToStoredPosition = function (
	manipulateLayer_,
	layerWrapper_,
	targetPosition_,
) {
	if (manipulateLayer_) {
		layerWrapper_.scrollTop = targetPosition_;
	} else {
		window.scroll(0, targetPosition_);
	}
};

__.debugScrollStacking = function (action_, index_) {
	if (__.debugActive) {
		console.info('StackingAction: ', action_);
		console.info('StackingIndex: ', index_);
		console.info(
			'ScrollPosition: ',
			__.scrollPositionStackingArray[index_],
		);
		if (__.layerStackingDirection === __.stackingDirections.upwards) {
			console.info('StackingDirection set to: Upwards (default)');
		} else {
			console.info('StackingDirection set to: Downwards');
		}
		console.info('------------------------------------------------');
	}
};

__.getTemplatePromise = function (attrs) {
	if (dynamicDot.requiresDynamicDot()) {
		return __.getTemplateFromBackend(attrs);
	} else {
		return __.getTemplateString(attrs);
	}
};

__.getTemplateFromBackend = function (attrs) {
	let templateSelector = null;

	if (!!attrs && !!attrs['data-layer-tpl']) {
		templateSelector = 'nm-id-' + attrs['data-layer-tpl'] + '-layer-tpl';
	} else {
		templateSelector = __.LAYER_TPL_ID;
	}

	return dynamicDot.getTemplateFromBackend(templateSelector);
};

/**
 * Looks up the template string in the DOM, stores and returns it.
 * @param {Array} attrs - the layer attributes
 * @returns {void}
 */
__.getTemplateString = function (attrs) {
	let templateSelector = '#' + __.LAYER_TPL_ID;
	let templateEl;

	if (!!attrs && !!attrs['data-layer-tpl']) {
		templateSelector = '#nm-id-' + attrs['data-layer-tpl'] + '-layer-tpl';
	}
	templateEl = document.querySelector(templateSelector);
	return Promise.resolve(templateEl.innerHTML || '');
};

/**
 * handle error when opening new layer
 * @returns {void}
 */
__.handleError = function () {
	let tplStr;
	tplStr = template.render(errorTemplate, {
		errorMessage:
			window.i18n['error-layer.unknown-error'] ||
			'Es ist ein unbekannter Fehler aufgetreten',
	});
	api.open('error', tplStr)
		.then(function () {
			__.domEventDelegate.on(
				'click',
				'.nm-error-layer .nm-j-ok-btn',
				__.layerCloseHandler,
			);
		})
		.catch(function (err) {
			console.error(err);
		});
	history.back();
};

export { exports as cms };
