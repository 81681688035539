/* global SETUPS */
const __ = {};
const exports = {
	__: __,
};

exports.requiresDynamicDot = function () {
	return document.querySelector('#nm-id-default-layer-tpl') == null;
};

exports.getTemplateFromBackend = function (templateId) {
	return new Promise(function (resolve, reject) {
		let path = SETUPS.get('nemo.market.homepage');
		if (!path) {
			const indexExtension = window.location.pathname.lastIndexOf('.');
			path = window.location.pathname.substring(0, indexExtension);
		}

		const targetUrl = path + '.dot-template.' + templateId + '.html';
		fetch(targetUrl, {
			headers: {
				Accept: 'text/plain',
			},
			method: 'GET',
		})
			.then(function (response) {
				if (response.ok) {
					return response.text();
				} else {
					throw new Error(response.error);
				}
			})
			.then(function (content) {
				resolve(content ? content.trim() : content);
			})
			.catch(function (error) {
				console.error('Error while reading layer template', error);
				reject(error);
			});
	});
};

export { exports as dynamicDot };
