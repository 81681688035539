import { appEvents } from '../app-events';
import { dom } from '../../../js/utils-bundle';
import { dom as layerDom } from './dom';

const __ = {};
const exports = {
	__: __,
};

/**
 * add events ;)
 * @returns {void}
 */
__.addEvents = function () {
	__.eventBus.on(appEvents.LAYER_LOADED, __.handerLayerLoaded);
	__.eventBus.on(appEvents.LAYER_CLOSED, __.handerLayerClosed);
};

/**
 * set the title back to the page's title, when the layer has been closed
 * @returns {void}
 */
__.handerLayerClosed = function () {
	const pageTitleElem = dom.getElement('#nm-page-title');

	if (!!pageTitleElem && pageTitleElem !== null) {
		__.updateTitle(pageTitleElem.innerHTML);
	}
};

/**
 * extract the title of the layer from the payload an set the title to it
 * @param {Object} payload - the event payload containing the layer content element
 * @returns {void}
 */
__.handerLayerLoaded = function (payload) {
	let layerTitle;

	if (!!payload && !!payload.element) {
		layerTitle = layerDom.extractLayerTitle(payload.element);
		__.updateTitle(layerTitle);
	}
};

/**
 * update the HTML title
 * @param {string} newTitle - the new title to set
 * @returns {void}
 */
__.updateTitle = function (newTitle) {
	let div, decodedTitle;

	if (!!newTitle && newTitle !== '') {
		// resolve html encoding
		div = document.createElement('div');
		div.innerHTML = newTitle;
		decodedTitle = div.firstChild.nodeValue;
		layerDom.setPageTitle(decodedTitle);
	}
};

exports.initialize = function (eventEmitter) {
	__.eventBus = eventEmitter;
	__.addEvents();
};

export { exports as title };
