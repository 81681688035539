import { stateRegistry } from 'microkernel';

/**
 * update Footnotes
 * @param {object} state - previous state
 * @param {object} parameterObject - parameter object
 * @returns {object} - new state
 */
const updateFootnotes = function (state = {}, parameterObject = {}) {
	return {
		...state,
		...parameterObject,
	};
};

class FootnotesStore {
	static get ACTIONS() {
		return { UPDATE_FOOTNOTES: 'updateFootnotes' };
	}

	/**
	 * add a footnotes store to the stateRegistry
	 * @returns {void}
	 */
	static addFootnotesStore() {
		stateRegistry.addStore('dbadFootnotesStore', {}, { updateFootnotes });
	}
}

export { FootnotesStore, updateFootnotes as _testing_only_updateFootnotes };
