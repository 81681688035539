import './application/footnotes/footnotes';
import './application/stores/page-store';
import { ModalLayer, modalLayer } from './application/modal-layer/modal-layer';
import { FootnotesEngine } from './application/footnotes/footnotes-engine';
import ModalLayerContentBase from './application/modal-layer/modal-layer-content-base';
import ModalLayerWrapper from './application/modal-layer/modal-layer-wrapper';
import { appEvents } from './application/app-events';
import { cache } from './application/cache';
import { client as clientLayer } from './application/layer/client';
import { content } from './application/content';
import { dynamicDot } from './application/dot/dynamic-dot';
import { dynamicSmoothScroll } from './application/dynamic-smooth-scroll';
import { animation as layerAnimation } from './application/layer/animation';
import { api as layerApi } from './application/layer/api';
import { cms as layerCms } from './application/layer/cms';
import { dom as layerDom } from './application/layer/dom';
import { element as layerElement } from './application/layer/element';
import { errorTemplate as layerErrorTemplate } from './application/layer/error-template';
import { title as layerTitle } from './application/layer/title';
import { modalLayerFootnoteEngineTemplate } from './application/modal-layer/modal-layer-footnote-engine-template';
import { dom as pageDom } from './application/page/dom';
import { main as pageMain } from './application/page/main';
import { preload as pagePreload } from './application/page/preload';
import { title as pageTitle } from './application/page/title';
import { type as pageType } from './application/page/type';
import { router } from './application/router';
import { routerState } from './application/router-state';
import { scroll } from './application/scroll';
import { signal } from './application/signal';
import { staticContent } from './application/static-content';
import { suppressSpaceAfterToolbar } from './application/layer/suppressSpaceAfterToolbar';

/**
 * global internationalization key|value storage
 */
window.i18n = window.i18n || {};

/**
 * modules to be initialized with event emitter
 */
const toBeInitialized = [
	layerApi,
	clientLayer,
	layerCms,
	layerTitle,
	pageMain,
	pagePreload,
	pageTitle,
	pageType,
	router,
	scroll,
	suppressSpaceAfterToolbar,
];

/**
 * initialize modules and inject signal dependency
 */
const eventEmitter = signal.getEmitter();
toBeInitialized.forEach((module) => {
	try {
		module.initialize(eventEmitter);
	} catch (err) {
		console.error(err);
	}
});

export {
	appEvents,
	cache,
	content,
	layerAnimation,
	layerApi,
	clientLayer,
	FootnotesEngine,
	layerCms,
	layerDom,
	layerElement,
	layerErrorTemplate,
	layerTitle,
	modalLayer,
	ModalLayer,
	ModalLayerContentBase,
	modalLayerFootnoteEngineTemplate,
	ModalLayerWrapper,
	pageDom,
	pageMain,
	pagePreload,
	pageTitle,
	pageType,
	router,
	routerState,
	scroll,
	signal,
	staticContent,
	dynamicSmoothScroll,
	dynamicDot,
	suppressSpaceAfterToolbar,
};
