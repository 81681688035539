/* global SETUPS */
import { stateRegistry } from 'microkernel';

export default class AudiPageStore extends HTMLElement {
	constructor() {
		super();

		this._addPageStore();
	}

	connectedCallback() {
		this._handlePageUpdate = this._handlePageUpdate.bind(this);
		this._handleLayerUpdate = this._handleLayerUpdate.bind(this);

		document.addEventListener('PAGE_READY', this._handlePageUpdate);
		document.addEventListener('LAYER_LOADED', this._handleLayerUpdate);
		document.addEventListener('LAYER_CLOSED', this._handleLayerUpdate);

		this._handlePageUpdate();
	}

	/**
	 * @returns {void} nothing
	 */
	_addPageStore() {
		const pageStoreElement = this;

		stateRegistry.addStore(
			'dbadPageStore',
			{
				baseURL: window.location.origin,
				currentURL: window.location.href,
				isLayer: this._isLayer(),
				language: this._getLanguage(
					window.location.href,
					window.location.pathname,
				),
				market: this._getMarket(
					window.location.href,
					window.location.pathname,
				),
				pageName: document.title,
				pageTemplate: this._getPageTemplate(),
				pageType: this._getPageType(),
				referringURL: document.referrer || '',
				scopes: this._getScopes(),
			},
			{
				updateLayerState: function (state) {
					return {
						...state,
						currentURL: window.location.href,
						isLayer: pageStoreElement._isLayer(),
						pageName: document.title,
					};
				},
				updatePageState: function (state, parameterObject) {
					return {
						...state,
						currentURL: window.location.href,
						isLayer: pageStoreElement._isLayer(),
						pageName: document.title,
						pageTemplate: parameterObject.pageTemplate,
						pageType: parameterObject.pageType,
					};
				},
			},
		);
	}

	/**
	 * get scopes from markup
	 * @returns {String} - scopes (comma-separated)
	 */
	_getScopes() {
		const scopesNodes = document.querySelector('.nm-activated-scopes');
		if (scopesNodes) {
			return scopesNodes.dataset.scopes;
		}
		return '';
	}

	/**
	 * @returns {void} nothing
	 */
	_handlePageUpdate() {
		stateRegistry.triggerAction('dbadPageStore', 'updatePageState', {
			currentURL: window.location.href,
			isLayer: this._isLayer(),
			pageName: document.title,
			pageTemplate: this._getPageTemplate(),
			pageType: this._getPageType(),
		});
	}

	/**
	 * @returns {void} nothing
	 */
	_handleLayerUpdate() {
		stateRegistry.triggerAction('dbadPageStore', 'updateLayerState', {
			currentURL: window.location.href,
			isLayer: this._isLayer(),
			pageName: document.title,
		});
	}

	/**
	 * @returns {string} page template
	 */
	_getPageTemplate() {
		if (!SETUPS) {
			return '';
		}

		return SETUPS.get('page.template') || '';
	}

	/**
	 * @returns {string} page template
	 */
	_getPageType() {
		return document.body.getAttribute('page-type') || 'default';
	}

	/**
	 * _isLayer - checks if current page shows a layer
	 * @returns {boolean} true if type is layer | false
	 */
	_isLayer() {
		if (this._hasSubString(window.location.href, 'jslayer=')) {
			return true;
		}

		if (this._hasSubString(window.location.href, 'layer=')) {
			return true;
		}

		if (document.body.classList.contains('modal-layer--open')) {
			return true;
		}

		if (document.querySelector('.nm-layer-conflict.nm-layer-visible')) {
			return true;
		}

		return false;
	}

	/**
	 * _getMarket - get the market of the page
	 * @param {string} href_ - the current href
	 * @param {string} pathname_ - the current href
	 * @returns {string} - market ID
	 */
	_getMarket(href_, pathname_) {
		let market = '';

		if (this._hasLocalMarket()) {
			market = this._getLocalMarket();
		} else if (this._getMarketFromPageID(href_, pathname_)) {
			market = this._getMarketFromPageID(href_, pathname_);
		}

		return market.toUpperCase();
	}

	/**
	 * check for local market settings in SETUPS
	 * @returns {boolean} localCountry exists / does not exist
	 */
	_hasLocalMarket() {
		return (
			this._hasSetupsValue('LocaleCountry') ||
			this._hasSetupsValue('nemo.locale.country')
		);
	}

	/**
	 * return for local market settings in SETUPS
	 * @returns {string} localMarket - market string
	 */
	_getLocalMarket() {
		let localMarket = '';

		if (this._hasSetupsValue('LocaleCountry')) {
			localMarket = this._getSetupsValue('LocaleCountry');
		} else if (this._hasSetupsValue('nemo.locale.country')) {
			localMarket = this._getSetupsValue('nemo.locale.country');
		}

		return localMarket;
	}

	/**
	 * _getMarketFromPageID - get the market of the page
	 * @param {string} href_ - the current href
	 * @param {string} pathname_ - the current href
	 * @returns {string} - market
	 */
	_getMarketFromPageID(href_, pathname_) {
		const positionOfMarketInPageID = 1;
		let market = '';
		let pageIDs = this._getPageID(href_, pathname_).split('/');

		if (pageIDs && pageIDs[positionOfMarketInPageID]) {
			market = pageIDs[positionOfMarketInPageID];
		}

		return market;
	}

	/**
	 * _getLanguage - get the language of the page
	 * @param {string} href_ - the current href
	 * @param {string} pathname_ - the current href
	 * @returns {string} - language
	 */
	_getLanguage(href_, pathname_) {
		let language = '';

		if (this._hasLocalLanguage()) {
			language = this._getLocalLanguage();
		} else {
			language = this._getLanguageFromPageID(href_, pathname_);
		}

		return language.toLowerCase();
	}

	/**
	 * check for local language settings in SETUPS
	 * @returns {boolean} localLanguage exists / does not exist
	 */
	_hasLocalLanguage() {
		return (
			this._hasSetupsValue('LocaleLanguage') ||
			this._hasSetupsValue('nemo.locale.language')
		);
	}

	/**
	 * return for local language settings in SETUPS
	 * @returns {string} localLanguage - language string
	 */
	_getLocalLanguage() {
		let localLanguage = '';

		if (this._hasSetupsValue('LocaleLanguage')) {
			localLanguage = this._getSetupsValue('LocaleLanguage');
		} else if (this._hasSetupsValue('nemo.locale.language')) {
			localLanguage = this._getSetupsValue('nemo.locale.language');
		}

		return localLanguage;
	}

	/**
	 * _getLanguageFromPageID - get the language of the page
	 * @param {string} href_ - the current href
	 * @param {string} pathname_ - the current href
	 * @returns {string} - language
	 */
	_getLanguageFromPageID(href_, pathname_) {
		const positionOfLanguageInPageID = 3;
		let language = '';
		let pageIDs = this._getPageID(href_, pathname_).split('/');

		if (pageIDs && pageIDs[positionOfLanguageInPageID]) {
			language = pageIDs[positionOfLanguageInPageID];
		}

		return language.toLowerCase();
	}

	/**
	 * getPageID - get page id for tracking
	 * @param {string} href_ - current href
	 * @param {string} pathname_ - current pathname
	 * @returns {string} page id
	 */
	_getPageID(href_, pathname_) {
		let pageID = pathname_;

		if (this._hasSubString(href_, 'jslayer=')) {
			pageID = this._getPageIdForJsLayer(pageID, href_);
		} else if (this._hasSubString(href_, 'layer=')) {
			pageID = this._getSubStringByMarker(href_, 'layer=');
		} else if (this._hasSubString(href_, 'page=')) {
			pageID = this._getSubStringByMarker(href_, 'page=');
		}

		pageID = this._removeTrailingHtmlExtension(pageID);

		return pageID;
	}

	/**
	 * _getPageIdForJsLayer - returns page ID part for a jsLayer URL
	 * @param {string} pageID_ - the pageID_ string
	 * @param {string} href_ - the href
	 * @returns {string} pageName - string without trailing '.html*'
	 */
	_getPageIdForJsLayer(pageID_, href_) {
		let jsLayerName = this._getSubStringByMarker(href_, 'jslayer=');
		let pageID = pageID_;

		if (this._hasSubString(href_, 'page=')) {
			pageID = this._getSubStringByMarker(href_, 'page=');
		}

		pageID = this._removeTrailingHtmlExtension(pageID);

		return pageID + '/' + jsLayerName;
	}

	/**
	 * return for any item in SETUPS by name
	 * @param {string} setupAttribute_ - name of the SETUPS value
	 * @returns {boolean} true if attribute exists and is not empty string / false otherwise
	 */
	_hasSetupsValue(setupAttribute_) {
		let hasValue = false;

		if (
			SETUPS &&
			SETUPS.get(setupAttribute_) &&
			SETUPS.get(setupAttribute_) !== ''
		) {
			hasValue = true;
		}

		return hasValue;
	}

	/**
	 * return value for any item in SETUPS by name
	 * @param {string} setupAttribute_ - name of the SETUPS value
	 * @returns {string} value - SETUPS attribute value
	 */
	_getSetupsValue(setupAttribute_) {
		let value = '';

		if (
			SETUPS &&
			SETUPS.get(setupAttribute_) &&
			SETUPS.get(setupAttribute_) !== ''
		) {
			value = SETUPS.get(setupAttribute_);
		}

		return value;
	}

	/*
	 * _hasSubString - checks a string for a certain string occurence
	 * @param {string} searchString_ - the input string
	 * @param {string} wantedSubString_ - the string to search for
	 * @return {string} the substring
	 */
	_hasSubString(searchString_, wantedSubString_) {
		return searchString_.indexOf(wantedSubString_) !== -1;
	}

	/*
	 * _getSubStringByMarker - returns a string part after found marker
	 * @param {string} string_ - the input string
	 * @param {string} marker_ - the marker string
	 * @return string} the substring
	 */
	_getSubStringByMarker(string_, marker_) {
		if (!this._hasSubString(string_, marker_)) {
			return '';
		}

		return string_.substr(string_.indexOf(marker_) + marker_.length);
	}

	/**
	 * remove possible trailing '.html*' from string
	 * @param {string} string_ - the input string
	 * @returns {string} pageName - string without trailing '.html*'
	 */
	_removeTrailingHtmlExtension(string_) {
		let cleanString = string_;

		if (cleanString.indexOf('.html') > 0) {
			cleanString = cleanString.substr(0, cleanString.indexOf('.html'));
		}

		return cleanString;
	}
}

if (window.customElements.get('audi-page-store') === undefined) {
	window.customElements.define('audi-page-store', AudiPageStore);
}
