class ModalLayer {
	constructor() {
		this._registerdLayers = new Map();
		this._registeredLayers = new Map();
	}

	static get MODAL_LAYER_OPEN_EVENT() {
		return 'modalLayer:open';
	}

	static get MODAL_LAYER_CLOSE_EVENT() {
		return 'modalLayer:close';
	}

	/**
	 * getLayerContent
	 * @param {object} payload - object with specific data
	 * @returns {string} returns HTML String of Layer Content
	 */
	async getLayerContent(payload) {
		const layerElement = this._registerdLayers.get(payload.layerType);
		const modalLayer = this._registeredLayers.get(payload.layerType);
		let layerContent;

		if (layerElement) {
			layerContent = await layerElement.getContent(payload);
		} else if (modalLayer) {
			layerContent = await modalLayer.getContent(payload);
		} else {
			throw new Error('modal layer content not found.');
		}
		return layerContent;
	}
	/**
	 * registerLayerType
	 * @param {BasicModalLayerContent} basicLayerContentClass - BasicLayerContent
	 * @returns {void} returns nothing
	 * @public
	 * @deprecated
	 */
	registerLayerType(basicLayerContentClass) {
		this._registerdLayers.set(
			basicLayerContentClass.type,
			basicLayerContentClass,
		);
	}

	registerModalLayer(modalLayerType_, modalLayerOptions_) {
		this._registeredLayers.set(modalLayerType_, modalLayerOptions_);
	}

	/**
	 * unregisterLayerType
	 * @param {string} type - type
	 * @returns {void} returns nothing
	 * @deprecated
	 */
	unregisterLayerType(type) {
		this._registerdLayers.delete(type);
	}

	unregisterModalLayer(modalLayerType_) {
		this._registeredLayers.delete(modalLayerType_);
	}
}

// append Modal Layer Wrapper to body
if (!document.querySelector('.modal-layer')) {
	let modalLayerWrapper = document.createElement('modal-layer-wrapper');
	document.body.appendChild(modalLayerWrapper);
}

const modalLayer = new ModalLayer();
export { modalLayer, ModalLayer };
