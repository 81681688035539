import { ModalLayer, modalLayer } from './modal-layer';
import { appEvents } from '../app-events';
import { dom } from '../../../js/utils-bundle';
import { signal } from '../signal';

export default class ModalLayerWrapper extends HTMLElement {
	constructor() {
		super();
		this.layerStack = [];
		this._bindContextToFunctions();
	}

	/**
	 * static getter function for default CSS Selectors
	 * @static
	 * @returns {object <{closeButton: string, close: string, confirmButton: string, open: string, shader: string}>} defaults
	 */
	static get defaults() {
		return {
			attributeState: 'data-layer-active',
			centerVertically: 'modal-layer--centered',
			close: 'modal-layer--close',
			contentWrapper: '.modal-layer__content',
			contentWrapperClassName: 'modal-layer__content',
			contentWrapperHide: 'modal-layer__content--hide',
			contentWrapperShow: 'modal-layer__content--show',
			hidden: 'sc-hidden',
			modalLayer: '.modal-layer',
			modalLayerLink: '.js-modal-layer-link',
			modalLayerNoTransition: 'modal-layer--no-transition',
			modalLayerOptions: '.modal-layer__inner [data-layer-options]',
			open: 'modal-layer--open',
			shader: '.modal-layer__shader-click-area',
		};
	}

	static get defaultOptions() {
		return {
			addCssClass: '',
			centerVertically: false,
			onEscClick: true,
			onShaderClick: true,
		};
	}

	connectedCallback() {
		this.eventDelegate = dom.getEventDelegate('body');
		this.globalEventEmitter = signal.getEmitter();
		this._setElementDomProperties();
		this._addEvents();
	}

	disconnectedCallback() {
		this._removeEvents();
	}

	/**
	 * set element properties
	 * @returns {void} returns nothing
	 */
	_setElementDomProperties() {
		this.classList.add('modal-layer');
		this.setAttribute('data-layer-active', 'false');
		this.setAttribute('data-module', 'modal-layer');

		this.contentWrapper = document.createElement('div');
		this.contentWrapper.classList.add('modal-layer__content');

		this.shader = document.createElement('div');
		this.shader.classList.add('modal-layer__shader-click-area');

		this.appendChild(this.shader);
		this.appendChild(this.contentWrapper);

		this.modalLayer = document.querySelector(
			ModalLayerWrapper.defaults.modalLayer,
		);
		this.contentWrapper = this.modalLayer.querySelector(
			ModalLayerWrapper.defaults.contentWrapper,
		);
		this.shader = this.modalLayer.querySelector(
			ModalLayerWrapper.defaults.shader,
		);
	}

	/**
	 * _bindContextToFunctions - bind 'this' context to necessary functions
	 * @returns {void} returns nothing
	 */
	_bindContextToFunctions() {
		this._handleShaderClick = this._handleShaderClick.bind(this);
		this._handleModalLayerLinkClick =
			this._handleModalLayerLinkClick.bind(this);
		this._handleModalLayerEvents = this._handleModalLayerEvents.bind(this);
		this._handleEscClick = this._handleEscClick.bind(this);
		this._isLayerOpen = this._isLayerOpen.bind(this);
	}

	/**
	 * addEvents for EventEmitter and EventDelegate Click Events
	 * @returns {Promise<void>} nothing
	 * @private
	 */
	_addEvents() {
		document.addEventListener(
			ModalLayer.MODAL_LAYER_OPEN_EVENT,
			this._handleModalLayerEvents,
		);
		document.addEventListener(
			ModalLayer.MODAL_LAYER_CLOSE_EVENT,
			this._handleModalLayerEvents,
		);
		this.globalEventEmitter.on(
			appEvents.SC_ERROR,
			this._handleModalLayerEvents,
		);
		this.globalEventEmitter.on(
			ModalLayer.MODAL_LAYER_OPEN_EVENT,
			this._handleModalLayerEvents,
		);
		this.globalEventEmitter.on(
			ModalLayer.MODAL_LAYER_CLOSE_EVENT,
			this._handleModalLayerEvents,
		);
		this.eventDelegate.on(
			'click',
			ModalLayerWrapper.defaults.modalLayerLink,
			this._handleModalLayerLinkClick,
		);
	}

	/**
	 * removeEvents for EventEmitter and EventDelegate Click Events
	 * @returns {Promise<void>} nothing
	 * @private
	 */
	_removeEvents() {
		document.removeEventListener(
			ModalLayer.MODAL_LAYER_OPEN_EVENT,
			this._handleModalLayerEvents,
		);
		document.removeEventListener(
			ModalLayer.MODAL_LAYER_CLOSE_EVENT,
			this._handleModalLayerEvents,
		);
		this.globalEventEmitter.off(
			appEvents.SC_ERROR,
			this._handleModalLayerEvents,
		);
		this.globalEventEmitter.off(
			ModalLayer.MODAL_LAYER_OPEN_EVENT,
			this._handleModalLayerEvents,
		);
		this.globalEventEmitter.off(
			ModalLayer.MODAL_LAYER_CLOSE_EVENT,
			this._handleModalLayerEvents,
		);
		this.eventDelegate.off(
			'click',
			ModalLayerWrapper.defaults.modalLayerLink,
			this._handleModalLayerLinkClick,
		);
	}

	/**
	 * addLayerEvents - adds events when element is connected
	 * @returns {void} returns nothing
	 * @private
	 */
	_addLayerEvents() {
		this.shader.addEventListener('click', this._handleShaderClick);
		this.eventDelegate.on('keydown', 'body', this._handleEscClick);
	}

	/**
	 * removes element events
	 * handles click and key events
	 * @returns {void} returns nothing
	 * @private
	 */
	_removeLayerEvents() {
		this.shader.removeEventListener('click', this._handleShaderClick);
		this.eventDelegate.off('keydown', 'body', this._handleEscClick);
	}

	/**
	 * handle Modal Layer Events from LinkClicks and appends content
	 * @param {Event} event - click event
	 * @returns {Promise<void>} nothing
	 * @private
	 */
	async _handleModalLayerLinkClick(event) {
		this.options = null;
		event.preventDefault();
		const targetElement = event.target.closest(
			ModalLayerWrapper.defaults.modalLayerLink,
		);
		const payload = Object.assign({}, targetElement.dataset);
		payload.targetElement = targetElement;
		const layerContent = await modalLayer.getLayerContent(payload);
		this._openLayerContent(layerContent);
	}

	/**
	 * handle Modal Layer Events from EventEmitter
	 * @param {Event} event - EventEmitter Event
	 * @returns {Promise<void>} nothing
	 * @private
	 */
	async _handleModalLayerEvents(event) {
		let payload = event;
		this.options = null;

		if (event.detail) {
			payload = event.detail;
		}

		if (payload && payload.layerAction && payload.layerAction === 'close') {
			this._closeLayer();
		} else {
			if (payload && payload.options) {
				this.options = payload.options;
			}
			const layerContent = await modalLayer.getLayerContent(payload);
			this._openLayerContent(layerContent);
		}
	}

	/**
	 * handle shader click
	 * @returns {void} returns nothing
	 */
	_handleShaderClick() {
		if (this._adjustOption('onShaderClick')) {
			this._closeLayer();
		}
	}

	/**
	 * handle esc click
	 * @param {Event} event - click event
	 * @returns {void} returns nothing
	 */
	_handleEscClick(event) {
		if (
			document.body.classList.contains(ModalLayerWrapper.defaults.open) &&
			event.keyCode === 27 &&
			this._adjustOption('onEscClick')
		) {
			this._closeLayer();
		}
	}

	/**
	 * check if options are allowed
	 * @param {string} option - option type
	 * @returns {void} nothing
	 * @private
	 */
	_adjustOption(option) {
		let options = dom.getElement(
			ModalLayerWrapper.defaults.modalLayerOptions,
		);
		let layerOptions =
			Object.keys(options.dataset).length > 0
				? options.dataset.layerOptions
				: '{}';
		options = Object.assign(
			ModalLayerWrapper.defaultOptions,
			JSON.parse(layerOptions),
		);

		if (this.options) {
			options = Object.assign(
				ModalLayerWrapper.defaultOptions,
				this.options,
			);
		}
		return options[option];
	}

	/**
	 * append content of Custom Element to Content Wrapper
	 * @param {HTMLElement} layercontentElement - HTML of Custom Element
	 * @returns {void} nothing
	 * @private
	 */
	async _openLayerContent(layercontentElement) {
		if (this._isLayerOpen()) {
			this.layerStack.push(layercontentElement);
			return;
		}
		await this._hideLayerContent(this._isLayerOpen());
		this._clearLayerWrapper();
		this.contentWrapper.appendChild(layercontentElement);
		this._openLayer();
	}

	/**
	 * clears the wrapper inner HTML
	 * @returns {void} nothing
	 * @private
	 */
	_clearLayerWrapper() {
		this.contentWrapper.innerHTML = '';
		this.contentWrapper.className =
			ModalLayerWrapper.defaults.contentWrapperClassName;
	}

	/**
	 * public close modal layer method
	 * @param {BasicModalLayerContent} basicLayerContentClass - BasicLayerContent
	 * @param {Boolean} forceClose - true closes layer without transition
	 * @returns {void} returns nothing
	 * @public
	 */
	async close(basicLayerContentClass, forceClose) {
		await basicLayerContentClass.beforeClose();
		this._closeLayer(forceClose);
	}

	/**
	 * private close modal layer method
	 * sets modal layer state 'false' and removes Layer Events
	 * @param {Boolean} forceClose - true closes layer without transition
	 * @returns {void} returns nothing
	 * @private
	 */
	_closeLayer(forceClose) {
		if (forceClose) {
			this.modalLayer.classList.add(
				ModalLayerWrapper.defaults.modalLayerNoTransition,
			);
		}
		this.modalLayer.setAttribute(
			ModalLayerWrapper.defaults.attributeState,
			'false',
		);
		document.body.classList.remove(ModalLayerWrapper.defaults.open);
		this._removeLayerEvents();
		this._clearLayerWrapper();
		setTimeout(() => {
			this.modalLayer.classList.remove(
				ModalLayerWrapper.defaults.modalLayerNoTransition,
			);
			this.modalLayer.classList.remove(
				ModalLayerWrapper.defaults.centerVertically,
			);
			this._dispatchCustomEvent('LAYER_CLOSED');
			const nextLayer = this.layerStack.shift();
			if (nextLayer) {
				this._openLayerContent(nextLayer);
			}
		}, 250);
	}

	/**
	 * public open modal layer method
	 * @param {BasicModalLayerContent} basicLayerContentClass - BasicLayerContent
	 * @param {Boolean} forceOpen - true opens layer without transition
	 * @returns {void} returns nothing
	 * @public
	 */
	async open(basicLayerContentClass, forceOpen) {
		await basicLayerContentClass.beforeOpen();
		this._openLayer(forceOpen);
	}

	/**
	 * private open modal layer method
	 * sets modal layer state 'true' and add Layer Events
	 * @param {Boolean} forceOpen - true opens layer without transition
	 * @returns {void} returns nothing
	 * @private
	 */
	_openLayer(forceOpen) {
		if (forceOpen) {
			this.modalLayer.classList.add(
				ModalLayerWrapper.defaults.modalLayerNoTransition,
			);
		}

		this.modalLayer.setAttribute(
			ModalLayerWrapper.defaults.attributeState,
			'true',
		);
		document.body.classList.add(ModalLayerWrapper.defaults.open);

		const alignToCenter = this._adjustOption('centerVertically');

		if (alignToCenter) {
			this.modalLayer.classList.add(
				ModalLayerWrapper.defaults.centerVertically,
			);
		}

		this._showLayerContent();

		const contentWrapper = this.querySelector(
			ModalLayerWrapper.defaults.contentWrapper,
		);
		const contentWrapperClass = this._adjustOption('addCssClass');
		if (contentWrapper && !!contentWrapperClass) {
			contentWrapper.classList.add(contentWrapperClass);
		}

		this._addLayerEvents();

		setTimeout(() => {
			this.modalLayer.classList.remove(
				ModalLayerWrapper.defaults.modalLayerNoTransition,
			);
			this._dispatchCustomEvent('LAYER_LOADED', {
				element: this,
				type: 'modal_layer',
			});
		}, 250);
	}

	/**
	 * is layer open
	 * @returns {Boolean} returns boolean
	 * @private
	 */
	_isLayerOpen() {
		return document.body.classList.contains(
			ModalLayerWrapper.defaults.open,
		);
	}

	/**
	 * hide layer content
	 * @param {Boolean} layerOpen boolean
	 * @returns {void} returns nothing
	 * @private
	 */
	async _hideLayerContent(layerOpen) {
		if (layerOpen) {
			this.contentWrapper.classList.add(
				ModalLayerWrapper.defaults.contentWrapperHide,
			);
			this.contentWrapper.classList.remove(
				ModalLayerWrapper.defaults.contentWrapperShow,
			);
			return new Promise((resolve) => {
				setTimeout(() => {
					resolve('opacity on content wrapper was set.');
				}, 250);
			});
		}
		return Promise.resolve();
	}

	/**
	 * show layer content
	 * @returns {void} returns nothing
	 * @private
	 */
	_showLayerContent() {
		this.contentWrapper.classList.add(
			ModalLayerWrapper.defaults.contentWrapperShow,
		);
		this.contentWrapper.classList.remove(
			ModalLayerWrapper.defaults.contentWrapperHide,
		);
	}

	/**
	 * dispatches a custom event
	 * @param {String} eventName_ - name of custom event, e.g. LAYER_LOADED
	 * @param {Object} payload_ - payload
	 * @returns {void}
	 */
	_dispatchCustomEvent(eventName_, payload_) {
		try {
			let customEvent = new CustomEvent(eventName_, { detail: payload_ });

			document.dispatchEvent(customEvent);
		} catch (e) {
			console.error(
				'dispatchCustomEvent: ',
				'the custom-event for ' +
					eventName_ +
					' could not be dispatched',
				e,
			);
		}
	}
}

if (window.customElements.get('modal-layer-wrapper') === undefined) {
	window.customElements.define('modal-layer-wrapper', ModalLayerWrapper);
}
