import { appEvents } from '../app-events';
import { dom as pageDom } from './dom';

const __ = {};
const exports = {
	__: __,
};

__.addEvents = function () {
	__.eventBus.on(appEvents.PAGE_LOADED, __.handlerPageLoaded);
};

__.handlerPageLoaded = function (payload) {
	// set new title
	const domElement = payload.domElement,
		newTitle = pageDom.extractPageTitle(domElement);
	let div, decodedTitle;

	if (newTitle && newTitle !== '') {
		// resolve html encoding
		div = document.createElement('div');
		div.innerHTML = newTitle;
		decodedTitle = div.firstChild.nodeValue;
		pageDom.setPageTitle(decodedTitle);
	}
};

exports.initialize = function (eventEmitter) {
	__.eventBus = eventEmitter;
	__.addEvents();
};

export { exports as title };
