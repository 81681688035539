/**
 * The core layer module.
 */

import { animation as ANIMATION } from './animation';
import { appEvents } from '../app-events';
import { dom } from '../../../js/utils-bundle';
import { element } from './element';

// function(element, dom, ANIMATION, appEvents) {
const __ = {};

const exports = {
	__: __,
};

__.layers = {};

/*
 * Data structure for each layer.
 * @constructor
 */
__.Layer = function (type, html, onCloseRequest) {
	// Store type, html, onCloseRequest
	this.type = type;
	this.html = html;
	this.element = element.create(html);
	this.onCloseRequest = onCloseRequest;
	return this;
};

__.Layer.prototype.close = function () {
	return __.closeLayer(this);
};

// Update layer contents silently.
__.Layer.prototype.update = function (newContent) {
	this.element = element.create(newContent);
	this.html = newContent;
};

__.Layer.prototype.getElement = function () {
	return this.element;
};

__.Layer.prototype.remove = function () {
	delete __.layers[this.type];
	this.element = null;
	this.onCloseRequest = null;
	this.html = null;
	this.type = null;
};

exports.initialize = function (eventEmitter) {
	__.eventBus = eventEmitter;
	__.addEvents();
};

__.addEvents = function () {
	dom.getEventDelegate('body').off(
		'click',
		'.nm-layer-wrapper',
		exports.wrapperClickHandler,
	);
	dom.getEventDelegate('body').on(
		'click',
		'.nm-layer-wrapper',
		exports.wrapperClickHandler,
	);
};

/**
 * Checks if a wrapper click has been outside the layer element
 * (if a layer is currently open) and has to be treated as a
 * request to close layers.
 * @param {Event} e Event
 * @returns {void} nothing
 */
exports.wrapperClickHandler = function (e) {
	if (__.nrOfLayers() > 0) {
		if (__.isShaderClicked(e)) {
			exports.requestCloseall();
		}
	}
};

/**
 * open Opens a new layer.
 * @param {string} type - The layer type
 * @param {string} html - The content to be opened in a layer
 * @param {function} onCloseRequest - A callback that gets called when a third party attempts to close this layer
 * (by calling core/layer/base:requestCloseall()).
 * @returns {Promise} A layer Object to close or replace the layer or false.
 */
exports.open = function (type, html, onCloseRequest) {
	// eslint-disable-line max-statements
	let layer;
	// reject if params are not set or layer type already exists
	if (!type) {
		return Promise.reject(new Error('type not set.'));
	}
	if (!html) {
		return Promise.reject(new Error('html not set.'));
	}

	// check if no active layer of the same type already exists
	if (!__.hasActiveLayer(type)) {
		// reject if onCloseRequest is set but not a function
		if (!!onCloseRequest && typeof onCloseRequest !== 'function') {
			return Promise.reject(
				new Error('onCloseRequest has to be a function.'),
			);
		} else {
			// create and open new Layer
			layer = new __.Layer(type, html, onCloseRequest);
			__.layers[type] = layer;
			// create and animate DOM Elements
			return __.openLayer(layer);
		}
	} else {
		// do not close CMS or conflict Layers…they are updated silently
		if (type === 'cms' || type === 'conflict-layer') {
			layer = new __.Layer(type, html, onCloseRequest);
			return __.updateLayer(layer, html);
		}
		if (type === 'jslayer') {
			return Promise.resolve(__.layers.jslayer);
		}
		// other layer types do not allow duplicates to be opened
		return Promise.reject(new Error('Duplicate layer type. ' + type));
	}
};

/**
 * Returns true if both conditions are met:
 * - A layer object of the given type exists.
 *- The object is currently not being closed.
 *@param {string} type layer type
 *@returns {boolean} is active?
 */
__.hasActiveLayer = function (type) {
	return !!__.layers[type];
};

/**
 * Returns the number of currently open layers.
 * @returns {Numer} number of open layers
 */
__.nrOfLayers = function () {
	let count = 0,
		layer;
	for (layer in __.layers) {
		// eslint-disable-next-line no-prototype-builtins
		if (__.layers.hasOwnProperty(layer)) {
			count++;
		}
	}
	return count;
};

/**
 * Requests to close all layers. This will iterate over all currently
 * opened layers and either close them or call the callback if one was given
 * when the layer was opened (in which case closing the layer is the
 * responsibility of the module which initially opened that layer).
 * @returns {Promise} A Promise object.
 */
exports.requestCloseall = function () {
	let type;
	for (type in __.layers) {
		// eslint-disable-next-line no-prototype-builtins
		if (__.layers.hasOwnProperty(type)) {
			__.requestCloseLayer(type);
		}
	}
};
__.requestCloseLayer = function (type) {
	const layer = __.layers[type];
	if (typeof layer.onCloseRequest === 'function') {
		layer.onCloseRequest();
	} else {
		__.closeLayer(layer);
	}
};
/**
 * open method
 * handles communication with the animation module
 * @param {Layer} layer_ - Layer instance
 * @return {Promise} a promise
 */
__.openLayer = function (layer_) {
	const tmpLayer = layer_;
	const promise = ANIMATION.open(layer_.element, layer_);

	return promise
		.then(function (layer) {
			__.dispatchEvent(appEvents.LAYER_LOADED, {
				element: layer.element,
			});
			__.dispatchCustomEvent('LAYER_LOADED', {
				element: layer.getElement(),
				type: 'layer',
			});
			return layer;
		})
		.catch(function (err) {
			__.closeLayer(tmpLayer);
			console.error(
				'core/layer/animation:layerOpen: animation rejected.',
				err,
			);
			throw err;
		});
};

/**
 * dispatches a event via eventEmitter
 * @param {String} eventName_ - name of event
 * @param {Object} payLoad_ - Dom Element
 * @returns {void}
 */
__.dispatchEvent = function (eventName_, payLoad_) {
	__.eventBus.emit(eventName_, payLoad_);
};

/**
 * dispatches a custom event
 * @param {String} eventName_ - name of custom event, e.g. LAYER_LOADED
 * @param {Object} payload_ - payload
 * @returns {void}
 */
__.dispatchCustomEvent = function (eventName_, payload_) {
	try {
		let customEvent = new CustomEvent(eventName_, { detail: payload_ });

		document.dispatchEvent(customEvent);
	} catch (e) {
		console.error(
			'dispatchCustomEvent: ',
			'the custom-event for ' + eventName_ + ' could not be dispatched',
			e,
		);
	}
};

/**
 * close method
 * handles communication with the animation module
 * @param {Layer} layer_ - Layer instance
 * @return {Promise} a promise
 */
__.closeLayer = function (layer_) {
	const promise = ANIMATION.close(layer_.element, layer_);

	return promise
		.then(
			// success
			function (layer) {
				layer.remove();
			},
			function (err) {
				console.warn(
					'core/layer/animation:layerClose: animation rejected.',
					err,
				);
				throw err;
			},
		)
		.catch(function (err) {
			console.error(err);
		})
		.then(ANIMATION.toggleShader)
		.then(function () {
			__.dispatchEvent(appEvents.LAYER_CLOSED, {
				element: layer_.element,
			});
			__.dispatchCustomEvent('LAYER_CLOSED');
		});
};
/**
 * update an existing layer with conetnt of the same type
 * @param {Layer} layer_ - Layer Object
 * @param {HTMLElement} newContent_ - new html content
 * @returns {Promise} - new layer or Error
 */
__.updateLayer = function (layer_, newContent_) {
	let promise;
	layer_.update(newContent_);
	promise = ANIMATION.replace(layer_.getElement(), layer_);

	return promise
		.then(function (layer) {
			__.dispatchEvent(appEvents.LAYER_LOADED, {
				element: layer.getElement(),
			});
			__.dispatchCustomEvent('LAYER_LOADED', {
				element: layer.getElement(),
				type: 'layer',
			});
			return layer;
		})
		.catch(function (err) {
			console.warn(
				'core/layer/animation:layerUpdate: animation rejected.',
			);
			throw err;
		});
};

__.isShaderClicked = function (e) {
	return (
		dom.isElementOutsideOfElementWithSelector(
			e.target,
			'.nm-layer-inner',
		) && e.target.classList.contains('nm-layer-wrapper')
	);
};

export { exports as api };
