// TODO: refactor class

const exports = function (pathname, hash) {
	this.pathname = pathname || '';
	this.hash = hash || '';
	this.changes = [];
	this.init();
};

exports.URL_PARAM_SEPARATOR = '&';

exports.URL_PAGE_SEPARATOR = '~_~';

exports.VERSION = '0.2.1';

/**
 * @description Initializes the state. Detects necessary changes (i.e.
 * differences between pathname and hash/page).
 * @param {string} pathname path name
 * @param {string} hash hashstring
 * @returns {void} nothing
 */
exports.prototype.init = function (pathname, hash) {
	let obj;
	if (hash) {
		this.hash = hash;
	}
	this.hashObjects = this.parseHash(this.hash);
	this.attrs = {};
	for (obj in this.hashObjects) {
		// At init check if page in hash is different from pathname.
		if (obj === 'page') {
			if (this.hashObjects.page !== this.pathname) {
				this.changes.push([obj, this.hashObjects[obj], 1]);
			}
		}
		// find attrs
		else if (obj.match(/^data/g)) {
			this.attrs[obj] = this.hashObjects[obj];
		} else {
			this.changes.push([obj, this.hashObjects[obj], 1]);
		}
	}
};

/**
 * @description Updates the state by the pages hash.
 * Detects necessary changes.
 * @param {string} hash hashstring
 * @returns {void} nothing
 */
exports.prototype.update = function (hash) {
	// eslint-disable-line max-statements
	let // cache objects locally
		currentHashObjects = this.hashObjects,
		current,
		key;
	this.hash = hash;
	this.hashObjects = this.parseHash(hash);
	this.changes = [];
	this.currentAttrs = this.attrs || {};
	this.attrs = {};

	// filter attrs
	for (key in this.hashObjects) {
		// find attrs
		if (key.match(/^data/g)) {
			this.attrs[key] = this.hashObjects[key];
		}
		// find items to open
		else if (this.hashObjects[key] !== currentHashObjects[key]) {
			this.changes.push([key, this.hashObjects[key], 1]);
		}
	}

	// find items and metadata to close (except `page` which can't be closed)
	//
	for (current in currentHashObjects) {
		if (!this.hashObjects[current] && current !== 'page') {
			this.changes.push([current, currentHashObjects[current], 0]);
		}
	}

	/*
	 * check if `page` is set. if not, fall back to pathname,
	 * but only if page has been set before.
	 */
	if (!this.hashObjects.page && !!currentHashObjects.page) {
		this.hashObjects.page = this.pathname;
		this.changes.push(['page', this.pathname, 1]);
	}
};

/**
 * @description Parses the hash.
 * @param {string} hash_ hashstring
 * @returns {Object} hash data by channel
 */
exports.prototype.parseHash = function (hash_) {
	let hash,
		sections,
		obj = {},
		i,
		l,
		k,
		s,
		v;

	hash = hash_ || '';

	// remove leading # from hash
	if (hash.match(/^#/)) {
		hash = hash.substring(1);
	}

	if (hash) {
		sections = hash.split(exports.URL_PAGE_SEPARATOR);

		l = sections.length;

		// taken with courtesy from http://stackoverflow.com/a/2880929/351423

		let match,
			pl = /\+/g, // Regex for replacing addition symbol with a space
			search = /([^&=]+)=?([^&]*)/g;

		const decode = function (str) {
			return decodeURIComponent(str.replace(pl, ' '));
		};

		for (i = 0; (s = sections[i]), i < l; i++) {
			while ((match = search.exec(s)) !== null) {
				k = decode(match[1]);
				v = decode(match[2]);
				let dataMap = new Map(Object.entries(obj));
				dataMap.set(k, v);
				obj = Object.fromEntries(dataMap);
			}
		}
	}

	return obj;
};

/**
 * @description Returns and resets changes.
 * @returns {Array} Data to publish.
 *
 * NOTE: what about renaming to #getAndResetChanges !?
 */
exports.prototype.getChanges = function () {
	const localChanges = this.changes;
	this.changes = [];
	return localChanges;
};

exports.prototype.getAttrs = function () {
	const localAttrs = this.attrs || {};
	return localAttrs;
};

exports.prototype.getCurrentAttrs = function () {
	const localAttrs = this.currentAttrs || {};
	return localAttrs;
};

export { exports as routerState };
