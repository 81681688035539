class FootnotesRenderer {
	/*
	 * renderFootnotes - writes footnote markup to footnote container
	 * @param {Array} footnoteData_ - Array of footnote key / values
	 * @returns {void}
	 */
	static renderFootnotes(footnoteData_ = [], context_) {
		const renderContainer = context_;

		if (!renderContainer) {
			return;
		}

		renderContainer.innerHTML = '';

		const listElementsFragment = document.createDocumentFragment();

		footnoteData_.forEach((footnote) => {
			listElementsFragment.appendChild(footnote);
		});

		renderContainer.appendChild(listElementsFragment);

		FootnotesRenderer._dispatchRenderedEvent(renderContainer);
	}

	/*
	 * _dispatchRenderedEvent - dispatches FOOTNOTES_RENDERED event with render context
	 * @returns {void}
	 */
	static _dispatchRenderedEvent(context_) {
		document.dispatchEvent(
			new CustomEvent('FOOTNOTES_RENDERED', {
				detail: {
					context: context_,
				},
			}),
		);
	}
}

export default FootnotesRenderer;
