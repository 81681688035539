/* eslint-disable sort-keys */
const exports = {
	/**
	 * Configuration Events
	 */

	VERSION_LOAD_COMPLETE: 'version.load.complete',

	CARINFO_LOAD_COMPLETE: 'carinfo.load.complete',

	MODELSINFO_LOAD_COMPLETE: 'modelsinfo.load.complete',

	// Dom is ready and all required base config params (e.g. 'nemo.url.dpu') are avialable
	APP_READY: 'app.ready',

	// all promise-initialized modules are initialized
	APP_INITIALIZED: 'app.initialized',

	AVE_AVAILABLE: 'ave.available',
	AVE_STREAM_READY: 'ave.stream.ready',

	// configuration data was reset
	CONFIG_RESET: 'configuration.reset',

	// configuration data was updated
	CONFIG_UPDATE: 'configuration.update',

	// CONFIG_CANCEL: 'configuration.cancel',
	// user configuration was started
	CONFIG_START: 'configuration.start',

	// configuration json has been parsed (local configuration data has not yet been updated!)
	CONFIG_PARSED: 'configuration.parsed',

	// a configuration ('nm-j-configuration-item') item was clicked
	CONFIG_ITEM_CLICKED: 'configuration.item-clicked',

	DOM_UPDATED: 'dom.updated',

	TRIGGER_DOM_UPDATE: 'trigger.dom.update',

	// adopt a configuration (e.g. loaded by audicode or carstore)
	CONFIG_ADOPT: 'configuration.adopt-new',

	CONFIG_PDF: 'configuration.pdf',

	CONFIG_SEND: 'configuration.send',

	// save config in carstore
	CONFIG_SAVED: 'configuration.saved',

	// open conflict layer
	CONFIG_CONFLICT_OPEN: 'configuration.conflict-open',

	CONFIG_NON_CONFLICTING: 'configuration.non-conflicting',

	// dispatch change request at the beginning of an AJAX request
	CONFIG_CHANGEREQUEST_START: 'configuration.change-request-start',
	// dispatch change request at the end (success or failure) of an AJAX request
	CONFIG_CHANGEREQUEST_END: 'configuration.change-request-end',

	// check if a (pending) conflict exits
	CONFIG_CONFLICT_CHECK: 'configuration.conflict-check',

	// resolve a conflict by closing a conflict layer (accept or cancel)
	CONFLICT_CLOSE: 'conflict.resolve',

	// abort a transfer by clicking 'abort' within the transfer layer
	TRANSFER_CLOSE: 'transfer.cancel',

	/**
	 * Form Events
	 */
	FORM_FINISHED: 'form.finished',

	FORM_LOADED: 'form.loaded',

	HASH_CHANGE: 'hash.change',

	FORM_ERROR: 'form.error',

	INTERSTITIAL_LAYER_CLOSE: 'interstitial-layer.close',

	/**
	 * Layer Events
	 */
	LAYER_OPEN: 'layer.open',

	LAYER_CLOSE: 'layer.close',

	LAYER_INSERTED: 'layer.inserted',

	LAYER_LOADED: 'layer.loaded',

	LAYER_STATIC_OPEN: 'layer.static.open',

	LAYER_BEFORE_CLOSE_ALL: 'layer.before.closeall',

	LAYER_BEFORE_CLOSE: 'layer.before.close',

	LAYER_CLOSED: 'layer.closed',

	EQUIPMENT_SEARCH_LAYER_LOADED: 'equipmentSearchlayer.loaded',

	EQUIPMENT_SEARCH_LAYER_RESIZED: 'equipmentSearchlayer.resized',

	RENDERINGLAYER_LOADED: 'renderinglayer.loaded',

	RENDERINGLAYER_RESIZED: 'renderinglayer.resized',

	STARTLAYER_OPEN: 'startlayer.open',

	STARTLAYER_CLOSE: 'startlayer.close',

	TRANSFERLAYER_CLOSED: 'transferlayer.closed',

	SEARCH_OPEN: 'search.nemo.open',

	SEARCH_CLOSE: 'search.nemo.close',

	SEARCH_LOAD_AUDICODE: 'search.load.audicode',

	SEARCH_LOADED: 'search.loaded',

	MENUFLYOUT_OPEN: 'menuflyout.open',

	MENUFLYOUT_CLOSE: 'menuflyout.close',

	/**
	 * Page Events
	 */
	PAGE_NAV: 'page.nav',

	PAGE_OPEN: 'page.open',

	PAGE_LEAVE: 'page.leave',

	PAGE_READY: 'page.ready',

	PAGE_INSERTED: 'page.inserted',

	PAGE_LOADED: 'page.loaded',

	PAGE_ANIMATION_START: 'page.animation.start',

	PAGE_ANIMATION_COMPLETE: 'page.animation.complete',

	/**
	 * Mediagallery Events
	 */
	MEDIAGALLERY_CATEGORY_CHANGED: 'mediagallery.category.changed',
	MEDIAGALLERY_ITEM_CHANGED: 'mediagallery.item.changed',
	MEDIAGALLERY_RESIZED: 'mediagallery.resized',
	MEDIAGALLERY_SLIDED: 'mediagallery.slided',

	/**
	 * Custom Events
	 */
	UPDATE_VIEW_STATE: 'stage.updateViewState',

	STAGE_RENDERIMAGE_UPDATED: 'stage.renderimage.updated',

	EXTERNAL_EDITOR: 'configuration.external.nemo.configurator',

	ERROR: 'nemo.error',

	NOTIFICATION: 'nemo.notification',

	MYAUDI_LOGIN: 'myaudi.login',

	MYAUDI_LOGOUT: 'myaudi.logout',

	CONTENT_RESIZE: 'content.resize',

	/**
	 * AudiCode Events
	 */
	AUDICODE_DELETED: 'audicode.deleted',

	AUDICODE_SUCCESS: 'audicode.success',

	AUDICODE_FAILED: 'audicode.failed',
	/**
	 * Teaser Tracking
	 */
	TEASER_ACTIVATED: 'teaserActivated',
	TEASER_ALL_ACTIVATED: 'allTeasersActivated',

	RESET_POSTERVIEW: 'mss.reset.posterview',
	STOCKCAR_UPDATE: 'stck.update',
	SC_UPDATE_VIEW: 'sc:update:view',
	SC_UPDATE_VIEW_COUNTS: 'sc:update:view:counts',
	SC_RESET_RESULT_COUNT: 'sc:reset:result:counts',
	SC_UPDATE_VIEW_RANGE_FILTER: 'sc:update:view:range:filter',
	SC_UPDATE_VIEW_FAVORITES: 'sc:update:view:favorites',
	SC_DELETE_VIEW_FAVORITE: 'sc:delete:view:favorite',
	SC_DELETE_OUTDATET_FAVORITE: 'sc:delete:outdatet:favorite',
	SC_RESET_VIEW: 'sc:reset:view',
	SC_UPDATE_LOCATION_VIEW: 'sc:update:location:view',
	SC_ERROR_GPS: 'sc:error:gps',
	SC_UPDATE_VEHICLES: 'sc:increment:vehicles',
	SC_UPDATE_LOCATION_MODEL: 'sc:update:location:model',
	SC_UPDATE_LOCATION_FILTER: 'sc:update:location:filter',
	SC_UPDATE_FAVORITE_COUNT: 'sc:update:favorite:count',
	SC_COMPARE_UPDATE: 'sc:compare:update',
	SC_VERSION_LOADED: 'sc:version:loaded',
	SC_DETAILS_RATE_UPDATE: 'sc:details:updateRate',
	SC_ERROR: 'sc:error',

	CONTENT_RENDERED: 'content:rendered',

	/*
	 * DSH Conversion Tracking
	 */
	DSH_ZOOM_CHANGE: 'dsh:zoom.change',
	DSH_INPUT: 'dsh:input',
};
/* eslint-enable sort-keys */

export { exports as appEvents };
