/* global SETUPS */
/**
 * triggers 'page.open', 'page.leave', 'page.abort'
 */

import { appEvents } from './app-events';
import { dom } from '../../js/utils-bundle';
import { routerState } from './router-state';

const __ = {};
const exports = {
	__: __,
};
/**
 * scroll pos before hash change (applies only for empty new hash)
 */
let _scrollPos = false;
let _lastLocationHash = null;
/**
 * aborted state
 */
let _aborted = false;

__.pathBase = '';

/**
 * @describe Can we use pushState etc.?
 * @returns {boolean} - true if pushState can be used, false else
 */
exports.history_available = (function () {
	return !!(window.history && history.pushState);
})();

/**
 * registered selectors and channels
 */
__.registered = [];

/**
 * construct new hash string from old hash and new key value pair
 * @param {string} oldHash_ - the old has String
 * @param {string} keyName_ - the key name
 * @param {string} value_ - the key value
 * @param {string} action_ - the action
 * @param {Object} attrs_ - the attributes
 * @returns {string} the new hash string
 */
__.newHashString = function (oldHash_, keyName_, value_, action_, attrs_) {
	// eslint-disable-line max-params, max-statements
	let hashObjects;
	let hashParts;
	const action = action_ || 'open';
	const attrs = attrs_ || {};

	if (!keyName_) {
		return oldHash_;
	}
	hashObjects = __.state.parseHash(oldHash_);

	// remove key from hash if action is 'close'
	if (action === 'close') {
		if (hashObjects[keyName_] !== undefined) {
			delete hashObjects[keyName_];
		}
	}
	// ... or add new value
	else {
		hashObjects[keyName_] = value_;
	}
	hashParts = __.processHashKeys(hashObjects, action);
	hashParts = __.addAttrsToHashParts(hashParts, attrs);
	hashParts = __.removeAttrsFromHashParts(hashParts, attrs);

	return __.joinHashParts(hashParts, oldHash_);
};

/**
 * process the hash opbjects
 * @param {Array} hashObjects_ - the hash objects
 * @param {string} action_ - the action
 * @returns {Array} - the calculated hash parts
 */
__.processHashKeys = function (hashObjects_, action_) {
	const hashParts = [];
	let key, hashValue;

	for (key in hashObjects_) {
		if (hashObjects_[key]) {
			if (action_ === 'close' && key.match(/data-/)) {
				continue;
			}
			if (key === 'page') {
				history.pushState(
					{
						pageLoad: true,
					},
					null,
					hashObjects_[key],
				);
				__.updatePathBase();
				__.handleHistoryChange({
					state: {
						pageLoad: true,
					},
				});
				continue;
			}
			hashValue = hashObjects_[key];
			if (hashValue) {
				hashParts.push(key + '=' + hashValue);
			}
		}
	}
	return hashParts;
};

/**
 * add the attrs to the hash parts
 * @param {Array} hashParts_ the hash parts
 * @param {Array} attrs_ - the attributes
 * @returns {Array} - the attrs-enrichted hash parts
 */
__.addAttrsToHashParts = function (hashParts_, attrs_) {
	const hashParts = hashParts_;
	let attr;
	let attributeStringToPush;

	for (attr in attrs_) {
		if (attrs_[attr]) {
			attributeStringToPush = 'data-' + attr + '=' + attrs_[attr];
			if (
				!!hashParts &&
				hashParts.indexOf(attributeStringToPush) === -1
			) {
				hashParts.push('data-' + attr + '=' + attrs_[attr]);
			}
		}
	}
	return hashParts;
};

/**
 * remove explicitly handed attributes with value "false" from hash parts
 * @param {Array} hashParts_ - the hash parts
 * @param {Array} attrs_ - the attributes
 * @returns {Array} - the hash parts cleaned of unwanted attributes
 */
__.removeAttrsFromHashParts = function (hashParts_, attrs_) {
	const hashParts = hashParts_;
	let attr;
	let attributeStringToRemove;
	let indexToRemove;

	for (attr in attrs_) {
		if (!attrs_[attr]) {
			attributeStringToRemove = 'data-' + attr + '=true';
			if (
				!!hashParts &&
				hashParts.indexOf(attributeStringToRemove) > -1
			) {
				indexToRemove = hashParts.indexOf(attributeStringToRemove);
				hashParts.splice(indexToRemove, 1);
			}
		}
	}
	return hashParts;
};

/**
 * join the hash parts for a new hash string
 * @param {Array} hashParts_ - the hash parts
 * @param {string} oldHash_ - the old hash
 * @returns {string} - the new hash
 */
__.joinHashParts = function (hashParts_, oldHash_) {
	let result;

	if (!new RegExp(routerState.URL_PAGE_SEPARATOR).test(oldHash_)) {
		result = hashParts_.join(routerState.URL_PARAM_SEPARATOR);
	} else {
		result = hashParts_.join(routerState.URL_PAGE_SEPARATOR);
	}
	return result;
};

__.getChannelAttributes = function (el_, channel_) {
	let channelAttributes = {},
		key,
		nodeName,
		i,
		n = el_.attributes.length;

	for (i = n - 1; i > -1; i--) {
		nodeName = el_.attributes[i].nodeName;
		if (nodeName.match(/^data/) && nodeName.match(channel_)) {
			key = nodeName.replace(/^data-/, '');
			channelAttributes[key] = el_.attributes[i].nodeValue;
		}
	}
	return channelAttributes;
};

/**
 * publish necessary events
 * @param {Array} changes_ - changes array
 * @param {Array} attrs_ - attrs array
 * @param {Array} currentAttrs_ - current attrs array
 * @returns {void}
 */
__.publishChanges = function (changes_, attrs_, currentAttrs_) {
	let change, type, pathName, data, evtName, i, l;

	for (i = 0, l = changes_.length; i < l; i++) {
		change = changes_[i];
		if (change[0] === 'page') {
			pathName = change[2] === 0 ? location.pathname : change[1];
			__.eventBus.emit(appEvents.PAGE_OPEN, {
				pathname: pathName,
			});
		} else {
			type = change[2] === 0 ? '.close' : '.open';
			data = {};
			data[change[0]] = change[1];
			evtName = change[0] + type;
			__.eventBus.emit(evtName, [data, attrs_, currentAttrs_]);
		}
	}
};

/**
 * @param {string} hash_ - the hash string
 * @returns {boolean} - true, if hash is empty, else false
 */
__.hashIsEmpty = function (hash_) {
	let hash = '';

	if (hash_) {
		hash = hash_;
	}
	return hash.length === 0;
};

/**
 * check whether string matches regexp
 * @param {string} string_ - string to check
 * @param {string} regex_ - regexp to check
 * @returns {boolean} - true, in case string matches regexp, else false
 */
__.stringContainsRegexPattern = function (string_, regex_) {
	return regex_.test(string_);
};

/**
 * check whether hash contains invalid string
 * @param {string} hash_ - the hash to check
 * @returns {boolean} - true, in case contains invalid string, false else
 */
__.hasInvalidHashParam = function (hash_) {
	const url_regex = /(^\s*\/\/|:\/\/)/gi;
	let invalid = false,
		hash = hash_;
	if (hash) {
		if (hash.indexOf('=') > 0) {
			hash = hash.split('=')[1];
		}

		invalid = __.hashIsEmpty(hash);
		if (!invalid) {
			// disallow when an absolute url is defined as a reference
			invalid = __.stringContainsRegexPattern(
				decodeURIComponent(hash),
				url_regex,
			);

			// however, in that case allow absolute url when the same origin has been configured.
			if (invalid) {
				const regexOrigin = new RegExp(
					`^${dom.escapeRegExp(window.location.origin)}`,
					'i',
				);
				return !regexOrigin.test(decodeURIComponent(hash));
			}
		}
	}

	return invalid;
};

/**
 * try a hash update
 * @param {string} newHashString_ - the new hash string to set
 * @returns {void}
 */
__.tryHashUpdate = function (newHashString_) {
	// reset _aborted.
	_aborted = false;
	// trigger page.leave to provoce page.abort events.
	__.eventBus.emit(appEvents.PAGE_LEAVE);

	// if page.abort has not been triggered, change hash.
	if (!_aborted) {
		if (newHashString_) {
			_scrollPos = false;
			location.hash = newHashString_;
		} else {
			_scrollPos = window.pageYOffset;

			if (!!location.hash && location.hash !== '') {
				location.hash = ''; // do NOT set this to ' ' because it has side effects for in-page navigation
			}
		}

		_lastLocationHash = location.hash;
	}
};

/**
 * create state for pathname and hash
 * @param {string} pathname_ - the pathname for the new state
 * @param {string} hash_ - the pathname for the new state
 * @return {Object} new state
 */
__.createState = function (pathname_, hash_) {
	const hash = hash_ || '';
	const pathname = pathname_ || '';

	return new routerState(pathname, hash); // eslint-disable-line new-cap
};

/**
 * handle a history change event
 * @param {Event} event - the history change event
 * @returns {void}
 */
__.handleHistoryChange = function (event) {
	// wir haben entweder einen page-link event, oder ein history back auf einen vorherigen page-load
	if (event.state && !!event.state.pageLoad) {
		__.publishChanges([['page', location.pathname, 0]], [], []);
	}
};

/**
 * check whether in-page navigation or other links to open in page via JS are present
 * @returns {boolean} true, if links are present, else false
 */
__.isInpageNaviPresent = function () {
	const navSelector = SETUPS.get('nemo.nav.navSelector') + ' a';
	const pageOpenSelector = '.nm-pageOpen';
	const navLinks = [].slice.call(document.querySelectorAll(navSelector));
	const pageOpenLinks = [].slice.call(
		document.querySelectorAll(pageOpenSelector),
	);

	return navLinks.length > 0 || pageOpenLinks > 0;
};

/**
 * update the path base
 * @returns {void}
 */
__.updatePathBase = function () {
	__.pathBase = window.location.pathname.split('/');
	// remove page "pagename.html"
	__.pathBase.pop();
	__.pathBase.push('');
	__.pathBase = __.pathBase.join('/');
	// remove spaces
	__.pathBase = __.pathBase.split(' ').join('');
	SETUPS.set('nemo.core.router.pathBase', __.pathBase);
};

/**
 * handle a hash change
 * @returns {void}
 */
__.handleHashChange = function () {
	let hash;

	if (__.hasInvalidHashParam(location.hash)) {
		console.error('invalid hash param', location.hash);
		__.eventBus.emit(appEvents.ERROR);
		location.hash = location.hash.split('#')[0];
		return false;
	}

	hash = location.hash.split('#')[1] || '';
	if (hash) {
		hash = '#' + hash;
	}

	// reset scroll pos for empty hash
	if (_scrollPos) {
		window.scrollTo(0, _scrollPos);

		// reset the scroll position after resetting scroll position
		if (_lastLocationHash !== hash) {
			_scrollPos = false;
		}
	}

	__.eventBus.emit(appEvents.HASH_CHANGE, hash);
	__.state.update(hash);
	__.publishChanges(
		__.state.getChanges(),
		__.state.getAttrs(),
		__.state.getCurrentAttrs(),
	);
};

/**
 * reset function - currently not used within module, but only for the tests
 * @returns {void}
 */
__.reset = function () {
	__.state = this.createState(location.pathname, '');
	__.registered = [];
};

/**
 * add url selector
 * @param {string} url_ - the url
 * @param {string} urlSelector_ the url selector
 * @returns {string} - new url
 */
__.addUrlSelector = function (url_, urlSelector_) {
	const selectorPos = url_.lastIndexOf('.html');
	const newurl =
		url_.substring(0, selectorPos) +
		'.' +
		urlSelector_ +
		url_.substring(selectorPos);

	return newurl;
};

/**
 * get data of previous page
 * @param {string} location_ - the location string
 * @returns {Object} - previous page data
 */
__.getPreviousPageData = function (location_) {
	return {
		hash: location_.hash,
		hashIdentifier:
			location_.hash && location_.hash.indexOf('page=') >= 0
				? 'page'
				: '',
		hashShort:
			location_.hash && location_.hash.indexOf('page=') >= 0
				? location_.hash.split('page=')[1]
				: '',
		pathName: location_.pathname,
		url: location_.pathname + location_.hash,
	};
};

/**
 * get closest a tag for click event
 * @param {Event} event_ - the click event
 * @return {Element} link element
 */
__.getClosestATag = function (event_) {
	let link;

	if (event_.target.tagName === 'A') {
		link = event_.target;
	} else {
		link = dom.closest(event_.target, 'a');
	}
	return link;
};

/**
 * register selectors to hash key (and action)
 * @param {string} selector_ - the selector to register
 * @param {string} keyName_ - the key name to register to
 * @param {string} action_ - the action to register (optional; default: open)
 * @return {void}
 */
exports.register = function (selector_, keyName_, action_) {
	const action = action_ || 'open';
	__.registered.push([selector_, keyName_, action]);
	console.info(
		'ROUTER.register++++ selector: ' +
			selector_ +
			', keyName: ' +
			keyName_ +
			', action: ' +
			action,
	);
	__.legacyEventBus = dom.getEventDelegate('body');
	__.legacyEventBus.on(
		'click',
		selector_,
		__.handleRegisteredClicks(keyName_, action),
	);
};

/**
 * check whether exit condition for registered click handling is fulfilled
 * @param {string} link_ - link to check
 * @return {boolean} - true, if exit condition is fulfilled, else false
 */
__.isClickHandlerExitConditionFulfilled = function (link_) {
	const link = link_;
	let isExitConditionFulfilled = false;
	let iframePath;

	if (dom.getElementsArray('body.nm-layer-iframelayer').length) {
		console.info('IframelayerFallback-Mode');

		if (dom.isElement(link) && link.classList.contains('nm-layerLink')) {
			// if we are inside an iframe layer
			// make sure that links that change the iframe content
			// have the same 'iframelayer' selector
			iframePath = link.getAttribute('href');

			if (iframePath.indexOf('.iframelayer.') < 0) {
				iframePath = __.addUrlSelector(iframePath, 'iframelayer');
				link.setAttribute('href', iframePath);
			}
			isExitConditionFulfilled = true;
		}
	}
	if (
		(dom.isElement(link) && link.classList.contains('js-link-extern')) ||
		dom.getElementsArray('body.nm-layer-fallback').length
	) {
		isExitConditionFulfilled = true;
	}
	return isExitConditionFulfilled;
};

/**
 * handleRegisteredClicks returns a click handlder function
 * with keyName and action wrapped in a closure for later use
 * @param {string} keyName_ -  name of the link action (eg. layer,jslayer,page)
 * @param {[type]} action_ - name of the router action (open or close)
 * @return {function} event handler function
 */
__.handleRegisteredClicks = function (keyName_, action_) {
	return function (event) {
		let targetPath,
			channelAttributes,
			el = this,
			alternativeLink,
			link = __.getClosestATag(event);

		if (__.isClickHandlerExitConditionFulfilled(link)) {
			return true;
		}
		event.preventDefault();

		if (event.target.classList.contains('j-stop-propagation')) {
			event.stopPropagation();
		}
		targetPath = el.getAttribute('href')
			? el.getAttribute('href')
			: el.getAttribute('data-href');
		channelAttributes = __.getChannelAttributes(el, keyName_);

		if (!targetPath) {
			alternativeLink = dom.closest(event.target.parentNode, 'a');
			targetPath = alternativeLink
				? alternativeLink.getAttribute('href')
				: '';
		}

		channelAttributes = __.handleInnerLayerChannelAttributes(
			event,
			channelAttributes,
		);

		__.previousPage = __.getPreviousPageData(window.location);
		__.tryHashUpdate(
			__.newHashString(
				location.hash,
				keyName_,
				targetPath,
				action_,
				channelAttributes,
			),
		);
	};
};

/**
 * set innerlayer attribute to false in case it is not a inner layer case
 * @param {Event} event_ - the original click event
 * @param {Object} channelAttributes_ - the channel attributes
 * @returns {Object} - the patched channel attributes
 */
__.handleInnerLayerChannelAttributes = function (event_, channelAttributes_) {
	const channelAttributes = channelAttributes_;
	channelAttributes.innerlayer = false;

	if (__.shouldSetTabLayerChannelAttribute(event_)) {
		channelAttributes.innerlayer = 'true';
	}

	return channelAttributes;
};

/**
 * @param {Event} event - the original click event
 * @returns {boolean} - whether innerlayer attribute should be set
 */
__.shouldSetTabLayerChannelAttribute = function (event) {
	const isLayerTabNav = dom.closest(
		event.target,
		'.nm-navigation-layer-wrap .nm-navigation-derivative-sub-list',
	);
	const isLayerConfigTabNav = dom.closest(
		event.target,
		'.nm-layer-content .nm-navigation',
	);
	const isGalleryNavNext = dom.closest(
		event.target,
		'.nm-gallery-slides .nm-icon-next-arrow',
	);
	const isGalleryNavPrev = dom.closest(
		event.target,
		'.nm-gallery-slides .nm-icon-back-arrow',
	);

	let toReturn = false;

	if (
		!!isLayerTabNav ||
		!!isLayerConfigTabNav ||
		!!isGalleryNavNext ||
		!!isGalleryNavPrev
	) {
		toReturn = true;
	}
	return toReturn;
};

/**
 * open
 * @param {string} keyName_ - the key name
 * @param {string} href_ the url
 * @returns {void}
 */
exports.open = function (keyName_, href_) {
	__.tryHashUpdate(__.newHashString(location.hash, keyName_, href_, 'open'));
};

/**
 * close
 * @param {string} keyName_ - the key name
 * @param {string} href_ - the url
 * @returns {void}
 */
exports.close = function (keyName_, href_) {
	__.tryHashUpdate(__.newHashString(location.hash, keyName_, href_, 'close'));
};

/**
 * expose previous page
 * @returns {Object} - the previous page or null
 */
exports.getPreviousPage = function () {
	return __.previousPage ? __.previousPage : null;
};

/**
 * expose state
 * @returns {routerState} - the global state
 */
exports.getState = function () {
	return __.state;
};

/**
 * expose registered selectors and channels
 * @returns {Array} - the registered selectors and channels
 */
exports.getRegistered = function () {
	return __.registered;
};

/**
 * expose path base
 * @returns {string} - the path base
 */
exports.getPathBase = function () {
	return __.pathBase || '';
};

/**
 * initialize the router module
 * @param {eventEmitter} eventEmitter - eventEmitter
 * @returns {void}
 */
exports.initialize = function (eventEmitter) {
	let _notify;

	__.eventBus = eventEmitter;
	window.addEventListener('popstate', __.handleHistoryChange);
	// listen to hashchange
	window.addEventListener('hashchange', __.handleHashChange, false);

	if (__.hasInvalidHashParam(location.hash)) {
		console.error('invalid hash param', location.hash);
		__.eventBus.emit(appEvents.ERROR);
		location.hash = location.hash.split('#')[0];
		return false;
	}

	// TODO: check function scope
	__.state = __.createState(location.pathname, location.hash);
	_notify = function () {
		__.publishChanges(
			__.state.getChanges(),
			__.state.getAttrs(),
			__.state.getCurrentAttrs(),
		);

		// if there is no hash and inpage-navi existent, the entry has to be added the the push history
		if (!location.hash && __.isInpageNaviPresent()) {
			history.replaceState(
				{
					pageLoad: true,
				},
				null,
				location.href,
			);
		}
	};
	window.setTimeout(_notify, 100);
	__.updatePathBase();
};

export { exports as router };
