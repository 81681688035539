import { FootnotesHelper } from './footnotes-helper';
import { dom } from '../../../js/utils-bundle';
import { dynamicSmoothScroll } from '../dynamic-smooth-scroll';

export default class FootnotesSmoothScroll {
	constructor() {
		this._addEvents();
	}

	/**
	 * _addEvents - adds events to modelfinder filter elements
	 * @returns {void}
	 */
	_addEvents() {
		this._handleFootnoteReferenceClick =
			this._handleFootnoteReferenceClick.bind(this);

		dom.getEventDelegate('body').on(
			'click',
			FootnotesHelper.FOOTNOTES_REFERENCE_SELECTOR,
			this._handleFootnoteReferenceClick,
		);
	}

	/**
	 * _handleFootnoteReferenceClick - scrools smoothly to related footnote
	 * @param {Event} event_ - click event
	 * @returns {void}
	 */
	_handleFootnoteReferenceClick(event_) {
		event_.preventDefault();

		const clickedElement = event_.target.closest(
			FootnotesHelper.FOOTNOTES_REFERENCE_SELECTOR,
		);
		const footnote = this._getFootnoteByReference(clickedElement);

		if (footnote) {
			const context = dynamicSmoothScroll.getScrollContext();
			const scrollContext = context === document.body ? window : context; // browser independent scroll context
			const startingPosition =
				context === document.body
					? event_.pageY
					: event_.pageY + context.scrollTop;

			dynamicSmoothScroll.scroll(
				footnote,
				scrollContext,
				startingPosition,
			);
		}
	}

	/**
	 * _getFootnoteByReference - find footnote for clicked reference
	 * @param {HTMLElement} element_ - clicked footnote reference
	 * @returns {HTMLElement} footnote element
	 */
	_getFootnoteByReference(element_) {
		if (!element_ || !element_.getAttribute('href')) {
			return;
		}

		return dynamicSmoothScroll
			.getScrollContext()
			.querySelector(element_.getAttribute('href'));
	}
}
