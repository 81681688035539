import { eventEmitter } from '../vendor-bundle';

/**
 * The core signal module.
 * @exports core/signal
 */

const __ = {},
	exports = { __: __ };

__.defaultConfig = {
	maxListeners: 0,
	newListener: false,
	wildcard: true,
};
eventEmitter.prototype.one = eventEmitter.prototype.once;
eventEmitter.prototype.trigger = eventEmitter.prototype.emit;

__.defaultEmitter = new eventEmitter(__.defaultConfig); // eslint-disable-line new-cap
/*
 * The default emitter gets an alias for `emit` called `trigger`
 * for API compatibility with jQuery events.
 */
__.defaultEmitter.trigger = function () {
	__.defaultEmitter.emit.apply(this, arguments);
};

__.emitters = {
	default: __.defaultEmitter,
};

/**
 * Returns an eventEmitter instance.
 * @param {string} name -- The name of the emitter (optional).
 * @returns {eventEmitter} object.
 */
exports.getEmitter = function (name) {
	// Return the default emitter if no name is given.
	if (typeof name === 'undefined') {
		return __.emitters.default;
	}

	// Create new emitter for name if none exists yet.
	__.emitters[name] = __.emitters[name] || new eventEmitter(__.defaultConfig); // eslint-disable-line new-cap

	return __.emitters[name];
};

export { exports as signal };
