export default class ModalLayerContentBase extends HTMLElement {
	constructor() {
		super();
	}

	/**
	 * getContent
	 * @returns {String} returns HTML String of Layer Content
	 */
	static async getContent() {
		throw new Error(
			'get content method has to be overridden from layer content element!',
		);
	}

	/**
	 * type - use name of modal layer content element
	 * @returns {void} returns nothing
	 */
	static get type() {
		return 'ModalLayerContentBase';
	}

	/**
	 * beforeOpen - method for modal layer content element to handle something before layer opens
	 * @returns {Promise} returns Promise
	 */
	async beforeOpen() {
		return true;
	}

	/**
	 * beforeClose - method for modal layer content element to handle something before layer closes
	 * @returns {Promise} returns Promise
	 */
	async beforeClose() {
		return true;
	}

	/**
	 * _triggerOpen - searches for modal layer wrapper, and calls async open function on it.
	 * @param {Boolean} forceOpen - true opens layer without transition
	 * @returns {void} returns nothing
	 */
	async _triggerOpen(forceOpen = false) {
		try {
			const wrapper = this.closest('modal-layer-wrapper');
			await wrapper.open(this, forceOpen);
		} catch (error) {
			console.warn('_triggerOpen - modal layer wrapper missing.');
			throw error;
		}
	}

	/**
	 * _triggerClose - searches for modal layer wrapper, and calls async close function on it.
	 * @param {Boolean} forceClose - true closes layer without transition
	 * @returns {void} returns nothing
	 */
	async _triggerClose(forceClose = false) {
		try {
			const wrapper = this.closest('modal-layer-wrapper');
			await wrapper.close(this, forceClose);
		} catch (error) {
			console.warn('_triggerClose - modal layer wrapper missing.');
			throw error;
		}
	}
}

if (window.customElements.get('modal-layer-content-base') === undefined) {
	window.customElements.define(
		'modal-layer-content-base',
		ModalLayerContentBase,
	);
}
