/**
 * @description static Content Controller.
 * Gets and sets (html) content from js templates while using same the layer api as the ajax content.
 */

const __ = {},
	exports = {
		__: __,
	};

// storage for all url patterns an their callBack functions
__.registeredPatterns = {};
__.JS_LAYER_REGEX = /(jslayer:)(\w|\.|-|_)+(,)?/gi;

/**
 * Gets the content from the cace or the supplied URI
 * @param {string} urlString_ - URI
 * @returns {Promise} rendered content (html string) wrapped in another Promise (getLayerContent)
 */
exports.getContent = function (urlString_) {
	return __.getLayerContent(urlString_);
};

exports.isStaticContentRequest = function (urlString_) {
	return __.isStaticLayerRequest(urlString_);
};

// the url pattern matches a static layer request
__.isStaticLayerRequest = function (urlString_) {
	return !!urlString_.match(__.JS_LAYER_REGEX);
};

/**
 * @param {string} urlString_ - url string
 * @return {Object} url params as json
 */
__.parseParams = function (urlString_) {
	const params = {};
	let urlString;
	// get all the other params from this url
	urlString = (urlString_ || '').replace(__.JS_LAYER_REGEX, '');
	urlString.split(',').forEach(function (p) {
		const kv = p.split('=');
		params[kv[0]] = kv[1];
	});
	return params;
};

/**
 * parsePattern
 * @param {string} urlString_ url string
 * @returns {string} matched string
 */
__.parsePattern = function (urlString_) {
	const match = (urlString_ || '').match(__.JS_LAYER_REGEX);
	if (match) {
		return match[0].split(',')[0];
	} else {
		return null;
	}
};

/**
 * fetch static content for a layer
 * @param {string} urlString_ - special params for this request
 * @return {Promise} html string for layers wrapped in a Promise
 */
__.getLayerContent = function (urlString_) {
	const params = __.parseParams(urlString_);
	const patternName = __.parsePattern(urlString_);
	let promise;

	if (exports.isRegisterPattern(patternName)) {
		// get callback Promise
		promise = __.registeredPatterns[patternName](params);
	} else {
		promise = Promise.reject(
			'pattern ' + patternName + ' id not registered!!',
		);
	}

	return promise;
};

/**
 * register an url pattern and its callBack
 *@param {string} patternName_ - name of the pattern e.g. 'jslayer:mediathek'
 *@param {function} callBackPromise_ - promise function to be called when the url pattern matches
 *@return {void}
 */
exports.registerStaticRequest = function (patternName_, callBackPromise_) {
	__.registeredPatterns[patternName_] = callBackPromise_;
};

/**
 * check if a patern is registered for callbacks
 * @param {string} patternName_ - name isf the pattern
 * @return {boolean} registered or not
 */
exports.isRegisterPattern = function (patternName_) {
	return (
		!!__.registeredPatterns[patternName_] &&
		typeof __.registeredPatterns[patternName_] === 'function'
	);
};

export { exports as staticContent };
