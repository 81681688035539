/* global SETUPS */
import { dom } from '../../../js/utils-bundle';

const __ = {};

const exports = {
	__: __,
};

__.navURLs = null;
__.oDefaults = {
	nmContentWrapper: '.nm-wrapper-content',
	sClassAnimate: 'animate',
	sClassDirectionBackward: 'backward',
	sClassDirectionForward: 'forward',
	sSelectorContent: '.nm-content',
	sSelectorContentElem: '.nm-wrapper-content .nm-area-content',
	sSelectorNavigation: '#nm-navigation li, .sc-main-navigation-list li',
	sSelectorPageTitle: '#nm-page-title',
};

__.addClassToSelector = function (selector, classToAdd) {
	dom.getElement(selector).classList.add(classToAdd);
};

__.removeClassFromSelector = function (selector, classToRemove) {
	dom.getElement(selector).classList.remove(classToRemove);
};

/**
 * get URLs from all navigation <li> elements
 * @param {array<HTMLElement>} navLIs_ - array with <li>
 * @return {array} array of all found URLs (patched)
 */
__.extractNavigationURLs = function (navLIs_) {
	let tmpElem;
	__.navURLs = [];

	navLIs_.forEach(function (domElement) {
		tmpElem = dom.getElement('a', domElement).getAttribute('data-href');

		if (tmpElem) {
			__.navURLs.push(tmpElem);
		}
	});
	return __.navURLs;
};

exports.isBackwardsAnimation = function (newPageUrl_, currentUrl_) {
	let currentUrl = currentUrl_,
		newPageUrl = newPageUrl_;
	if (typeof currentUrl !== 'undefined') {
		currentUrl = currentUrl.replace(
			SETUPS.get('nemo.core.router.pathBase'),
			'',
		);
	} else {
		currentUrl = window.location.pathname.replace(
			SETUPS.get('nemo.core.router.pathBase'),
			'',
		);
	}
	newPageUrl = newPageUrl.replace(
		SETUPS.get('nemo.core.router.pathBase'),
		'',
	);
	if (__.navURLs === null) {
		exports.extractNavUrls(
			dom.getElement(__.oDefaults.sSelectorContentElem),
		);
	}
	return __.navURLs.indexOf(newPageUrl) < __.navURLs.indexOf(currentUrl);
};

/**
 * Check whether we're currently in NOMO context
 * @returns {boolean} is NOMO or not
 */
exports.isNomo = function () {
	const _contentEl = document.querySelector(__.oDefaults.sSelectorContent);
	return !!_contentEl && _contentEl.getAttribute('data-type') === 'nomo';
};

/**
 * Extract nav URLs for current page
 * @param {HTMLElement} contentAreaObj content wrapper
 * @returns {array} - 'keyified' page URLs to preload content for
 */
exports.extractNavUrls = function (contentAreaObj) {
	return __.extractNavigationURLs(
		dom.getElementsArray(__.oDefaults.sSelectorNavigation, contentAreaObj),
	);
};

/**
 * Get guid of current page
 * @returns {string} - GUID of current page
 */
exports.getCurrentPageId = function () {
	return dom
		.getElement(__.oDefaults.sSelectorContent)
		.getAttribute('data-guid');
};

/**
 * Set document title
 * @param {string} title - page title
 * @returns {void} nothing
 */
exports.setPageTitle = function (title) {
	document.title = title;
};

/**
 * Extract page title from DOM
 * @param {HTMLElement} contentAreaObj - content wrapper
 * @returns {string} page title
 */
exports.extractPageTitle = function (contentAreaObj) {
	const titleElem = dom.getElement(
		__.oDefaults.sSelectorPageTitle,
		contentAreaObj,
	);
	let toReturn = '';
	if (dom.isElement(titleElem)) {
		toReturn = titleElem.innerHTML;
	}
	return toReturn;
};

/**
 * Insert new page content
 * @param {string} content - HTML string
 * @returns {HTMLElement} inserted Element
 */
exports.insertNewPageContent = function (content) {
	const nmContentElement = dom.getElement(__.oDefaults.nmContentWrapper);
	dom.appendHtmlString(nmContentElement, content);

	__.processScripts(content);

	const insertedElement = dom.getElement(
		__.oDefaults.sSelectorContentElem + ':last-child',
	);
	return insertedElement;
};

__.processScripts = function (content) {
	const inMemoryDomElement = document.createElement('div');
	inMemoryDomElement.innerHTML = content;
	const scripts = inMemoryDomElement.querySelectorAll('script');
	const loadedScripts = document.head.querySelectorAll('script');
	const loadedScriptSources = [...loadedScripts].map((loadedScript) => {
		return loadedScript.getAttribute('src');
	});
	scripts.forEach((script) => {
		const src = script.getAttribute('src');
		if (
			script.getAttribute('noModule') === null &&
			![...loadedScriptSources].includes(src)
		) {
			const jsElement = document.createElement('script');
			jsElement.setAttribute('src', src);
			document.head.appendChild(jsElement);
		}
	});
};

/**
 * Prepare the page transition (set classes to reset positions), dependent on direction
 * @param {boolean} backwardAnimation - whether a backwards animation should be prepared
 * @returns {void} nothing
 */
exports.preparePageTransition = function (backwardAnimation) {
	const directionClass = backwardAnimation
		? __.oDefaults.sClassDirectionBackward
		: __.oDefaults.sClassDirectionForward;
	__.addClassToSelector(__.oDefaults.nmContentWrapper, directionClass);
};

/**
 * Trigger the page transition (by setting the animation class)
 * @returns {void} nothing
 */
exports.triggerPageTranstion = function () {
	__.addClassToSelector(
		__.oDefaults.nmContentWrapper,
		__.oDefaults.sClassAnimate,
	);
};

/**
 * Finish page transition (remove animation classes and remove prev page element), scroll to top
 * @returns {void} nothing
 */
exports.finishPageTransition = function () {
	const bodyEl = dom.getElement('body');
	__.removeClassFromSelector(
		__.oDefaults.nmContentWrapper,
		__.oDefaults.sClassAnimate,
	);
	__.removeClassFromSelector(
		__.oDefaults.nmContentWrapper,
		__.oDefaults.sClassDirectionBackward,
	);
	__.removeClassFromSelector(
		__.oDefaults.nmContentWrapper,
		__.oDefaults.sClassDirectionForward,
	);
	dom.removeElement(
		dom.getElement(__.oDefaults.sSelectorContentElem + ':first-child'),
	);
	bodyEl.classList.remove('nm-nav-fixed');
	bodyEl.classList.remove('nm-page-nav-is-open');
	if (window.pageYOffset > 0) {
		dom.scrollTo(0, 400);
	}
};

export { exports as dom };
