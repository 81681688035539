/* global SETUPS */
import { appEvents } from '../app-events';
import { content } from '../content';
import { errorTemplate } from '../layer/error-template';
import { api as layerApi } from '../layer/api';
import { dom as pageDom } from './dom';
import { router } from '../router';
import { template } from '../../../js/utils-bundle';

const __ = {};

const exports = {
	__: __,
};

__.eventBus = null;
__.currentPageId = null;
__.queue = [];
__.lock = false;
__.backwardsAnimation = false;
__.oDefaults = {
	navSelector: '',
};
__.triggerPageTransitionTimeout = null;
__.finishPageTransitionTimeout = null;

/**
 * @returns {void}
 */
__.initialize = function () {
	__.oDefaults.navSelector = SETUPS.get('nemo.nav.navSelector');
	__.currentPageId = pageDom.getCurrentPageId();
	__.addEvents();
};

/**
 * Add event handlers
 * @return {void} nothing
 */
__.addEvents = function () {
	// subscribe to page.open
	__.eventBus.on(appEvents.PAGE_OPEN, __.handlePageOpen);
	if (__.oDefaults.navSelector) {
		// register navigation clicks
		router.register(__.oDefaults.navSelector + ' a', 'page');
	} else {
		console.warn('nemo.nav.navSelector is undefined');
	}
	// register generic pageOpen links
	router.register('.nm-pageOpen', 'page');
};

/**
 * Handle animation completion
 * @returns {void} nothing
 */
__.handlePageTransitionComplete = function () {
	__.reset();

	// start new update if there were clicks in between
	if (__.queue.length > 0) {
		__.handlePageOpen(__.queue.pop());
		__.queue = [];
	} else {
		__.eventBus.emit(appEvents.PAGE_READY);

		// alternative for newer bundles without eventBus
		__.dispatchCustomEvent('PAGE_READY');
	}
};

/**
 * dispatches a custom-event
 * @param {string} eventName_ - name of custom event, e.g. PAGE_LOADED
 * @param {Object} payload_ - payload
 * @returns {void}
 */
__.dispatchCustomEvent = function (eventName_, payload_) {
	try {
		let customEvent;

		if (payload_) {
			customEvent = new CustomEvent(eventName_, { detail: payload_ });
		} else {
			customEvent = new CustomEvent(eventName_);
		}

		document.dispatchEvent(customEvent);
	} catch (e) {
		console.error(
			'dispatchCustomEvent: ',
			'the custom-event for ' + eventName_ + ' could not be dispatched',
			e,
		);
	}
};

/**
 * Handle Router Event 'page.open'
 * @param {Event} data_ event emitter payload
 * @returns {void} nothing
 */
__.handlePageOpen = function (data_) {
	if (this && typeof this.preventDefault === 'function') {
		this.preventDefault();
	}

	if (!__.isLocked()) {
		let pathName = data_.pathname;

		__.setLock(new Date().getTime());
		// add leading slash if necessary (IE location.pathname issue)
		if (pathName.substr(0, 1) !== '/') {
			pathName = '/' + pathName;
		}
		__.newPageUrl = pathName;
		content
			.getContent(pathName, false)
			.then(__.pageContentHandler)
			.catch(__.handlePageOpenError);
	} else {
		__.queue.push(data_);
	}
};

/**
 * Check for page transition lock
 * @returns {boolean} - whether page transition is currently locked
 */
__.isLocked = function () {
	return !!__.lock;
};

/**
 * Set page transition lock
 * @param {string} time - time string
 * @returns {void} nothing
 */
__.setLock = function (time) {
	__.lock = time;
};

/**
 * Reset lock, page id and title
 * @returns {void}
 */
__.reset = function () {
	__.lock = false;
	__.currentPageUrl = __.newPageUrl;
	__.newPageUrl = '';
	__.newPageId = null;
	__.newPageTitle = null;
};

/**
 * Callback called when new content available from content module
 * @param {string} content_ - HTML string
 * @returns {void} nothing
 */
__.pageContentHandler = function (content_) {
	const insertedPage = pageDom.insertNewPageContent(content_);

	__.eventBus.emit(appEvents.PAGE_LOADED, {
		domElement: insertedPage,
	});
	__.dispatchCustomEvent('PAGE_LOADED', {
		element: insertedPage,
		type: 'page',
	});
	__.backwardsAnimation = pageDom.isBackwardsAnimation(
		__.newPageUrl,
		__.currentPageUrl,
	);
	// prepare and trigger animation
	pageDom.preparePageTransition(__.backwardsAnimation);

	__.clearPageTransitionTimeouts();

	__.triggerPageTransitionTimeout = setTimeout(function () {
		pageDom.triggerPageTranstion();
	}, 10);

	// complete animation
	__.finishPageTransitionTimeout = setTimeout(function () {
		// cleanup dom for new page
		pageDom.finishPageTransition();
		__.handlePageTransitionComplete();
	}, 1000);
};

/**
 * Handle page open error
 * @param {Object} error - TODO
 * @returns {void}
 */
__.handlePageOpenError = function (error) {
	let tplStr;
	history.back();
	tplStr = template.render(errorTemplate, {
		errorMessage:
			window.i18n['error-layer.unknown-error'] ||
			'Es ist ein unbekannter Fehler aufgetreten',
	});
	console.error(error);
	layerApi.open('error', tplStr).then(function (layer) {
		const btn = layer
			.getElement()
			.querySelector('.nm-error-layer .nm-j-ok-btn');
		btn.addEventListener('click', function tmpBtnHandler(e) {
			if (e.preventDefault) {
				e.preventDefault();
			}
			// remove this handler
			e.target.removeEventListener(e.type, tmpBtnHandler);
			layer.close();
		});
	});
	__.eventBus.emit(appEvents.PAGE_READY);
	__.reset();
};

exports.initialize = function (eventEmitter) {
	__.eventBus = eventEmitter;
	__.initialize();
};

/**
 * clears timeouts for page transitioning
 * @returns {void}
 */
__.clearPageTransitionTimeouts = function () {
	if (__.triggerPageTransitionTimeout) {
		clearTimeout(__.triggerPageTransitionTimeout);
	}

	if (__.finishPageTransitionTimeout) {
		clearTimeout(__.finishPageTransitionTimeout);
	}
};

export { exports as main };
