import { dom } from '../../../js/utils-bundle';

const __ = {};

const exports = {
	__: __,
};

/**
 * Set document title
 * @param {string} title - the new title to set
 * @returns {void}
 */
exports.setPageTitle = function (title) {
	document.title = title;
};

/**
 * Extract layer title from DOM
 * @param {Element} domElement - the dom element to extract the title from
 * @returns {string} the extracted title if found, or an empty string
 */
exports.extractLayerTitle = function (domElement) {
	const titleElem = domElement
		? dom.getElement('.nm-layer-title', domElement)
		: null;
	let toReturn = '';
	if (titleElem !== null) {
		toReturn = titleElem.innerHTML;
	}
	return toReturn;
};

export { exports as dom };
