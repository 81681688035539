import { appEvents } from '../app-events';
import { dom } from '../../../js/utils-bundle';

const __ = {};
const exports = {
	__: __,
};

/**
 * add events ;)
 * @returns {void}
 */
__.addEvents = function () {
	__.eventBus.on(appEvents.LAYER_LOADED, __.handerLayerLoaded);
	__.eventBus.on(appEvents.LAYER_CLOSED, __.handerLayerClosed);
};

/**
 * removes the class nm-suppress-space-toolbar on closing the layer
 * @returns {void}
 */
__.handerLayerClosed = function () {
	const suppressToolbarElement = dom.getElement(
		'.nm-layer-toolbar.nm-suppress-space-toolbar',
	);

	if (!!suppressToolbarElement && suppressToolbarElement !== null) {
		suppressToolbarElement.classList.remove('nm-suppress-space-toolbar');
	}
};

/**
 * moves the class nm-suppress-space-toolbar some levels up to nm-layer-wrapper if defined within the layer html
 * @param {Object} payload - the event payload containing the layer content element
 * @returns {void}
 */
__.handerLayerLoaded = function (payload) {
	if (!!payload && !!payload.element) {
		const suppressToolbarElement = payload.element.querySelector(
			'.nm-suppress-space-toolbar',
		);

		if (!!suppressToolbarElement && suppressToolbarElement !== null) {
			const layerWrapper = document.querySelector('.nm-layer-toolbar');
			if (layerWrapper) {
				layerWrapper.classList.add('nm-suppress-space-toolbar');
			}
		}
	}
};

exports.initialize = function (eventEmitter) {
	__.eventBus = eventEmitter;
	__.addEvents();
};

export { exports as suppressSpaceAfterToolbar };
