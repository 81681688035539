import { FootnotesEngine } from './footnotes-engine';
import { FootnotesStore } from './footnotes-store';
import { stateRegistry } from 'microkernel';

export default class AudiFootnotes extends HTMLElement {
	constructor() {
		super();
		if (!window.footnotesEngine) {
			window.footnotesEngine = new FootnotesEngine();
		}
		this.footnoteItems = null;
	}

	/**
	 * initializes store and engine if footnotes exist
	 * @returns {void}
	 */
	connectedCallback() {
		const footnotesList = this.querySelector('.audi-footnotes-list');

		if (footnotesList && footnotesList.children.length > 0) {
			this.footnoteItems = this._parseFootnotesMarkup(
				footnotesList.children,
			);
		}

		this.parentElement.removeChild(this);
	}

	/**
	 * initializes store and engine if footnotes exist
	 * @returns {void}
	 */
	disconnectedCallback() {
		if (this.footnoteItems) {
			stateRegistry.triggerAction(
				'dbadFootnotesStore',
				FootnotesStore.ACTIONS.UPDATE_FOOTNOTES,
				this.footnoteItems,
			);
		}
	}

	/**
	 * parses footnote markup to map
	 * @param {NodeList} footnoteElements_ - the footnotes markup
	 * @returns {Object} footnoteItems
	 */
	_parseFootnotesMarkup(footnoteElements_ = []) {
		let footnoteItems = {};

		[...footnoteElements_].forEach((footnote) => {
			const footnoteId = footnote.getAttribute('id');
			const footnoteValue = footnote.outerHTML;

			footnoteItems[footnoteId] = footnoteValue;
		});

		return footnoteItems;
	}
}

if (window.customElements.get('audi-footnotes') === undefined) {
	window.customElements.define('audi-footnotes', AudiFootnotes);
}
