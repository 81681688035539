import { cache as CACHE } from './cache';
import { staticContent as STATIC_CONTENT } from './static-content';

const __ = {},
	exports = {
		__: __,
	};
// cache index
__.oIndex = {};
// counts running xhr's
__.iCounterXhr = 0;
// global cache for "fillcached"-URIs
__.aCacheURI = [];
// set number of parallel ajax requests
__.iParallelXHRMax = 3;
// @Todo refactor, @see __.rewriteUrl() !
__.sMarkerXHR = '.headless';
// marker-provider for headless urls
__.oMarkerProvider = [];
// list of all registered content prividers
__.registeredContentProviders = [];
// RegEx matching dynamic content urls
__.DYNAMIC_URL_PREFIX_REGEX = /js(-|_)link/g;
/**
 * Updates the index
 * @param {string}  sKey_        Convention: Convention: URI
 * @param {boolean} isAvailable_ [description]
 * @returns {void}
 */
__.updateIndex = function (sKey_, isAvailable_) {
	const sKey = encodeURIComponent(sKey_);
	if (isAvailable_) {
		__.oIndex[sKey] = true;
	} else {
		__.oIndex[sKey] = false;
		delete __.oIndex[sKey];
	}
};
/**
 * Returns the caching-status
 * @param {string}  sKey_ Convention: URI
 * @returns {boolean} is Cached?
 */
exports.isCached = function (sKey_) {
	const sKey = encodeURIComponent(sKey_);
	return !!__.oIndex[sKey];
};
/**
 * Processes the content by updating the index and pushing
 * it to the cache
 * @param {string} sKey_   Convention: URI
 * @param {string} sValue_ Content
 * @param {boolean} failsafeSet ???
 * @returns {void}
 */
exports.setContent = function (sKey_, sValue_, failsafeSet) {
	// FIXME: why is this error commented out ?
	// TODO: either throw error or remove condition
	// if (typeof (sValue_) !== 'string') {
	//	throw new Error("nemo.core.controller.content: exports.setContent requires 'String'");
	// }
	// set sKey_ in index to true or false, depending on return value of setItem.
	const written = CACHE.setItem('core.controller.content', sKey_, sValue_, {
		failsafeSet: failsafeSet,
	});
	if (!written) {
		// if we couldn't write to the cache it's safe to say that the whole cache is empty now
		// so we also need to reset the caching index, otherwise the index might say something is cached
		// that isn't really there anymore
		exports.flush();
	}
	__.updateIndex(sKey_, written);
};
/**
 * precondition: search url for matching marker for dynamic contentProvider
 * @param {string} url_ - url to examine
 * @returns {boolean} url contains marker for a dynamic contentprovider
 */
__.hasDynamicContentUrl = function (url_) {
	return (url_ || '').match(__.DYNAMIC_URL_PREFIX_REGEX);
};
/**
 * Gets the content from the cace or the supplied URI
 * @param {string} sKey_       Convention: URI
 * @param {boolean} cache Flag, if the content should be stored locally or not
 * @returns {Promise} content wrapped in a Promise
 */
exports.getContent = function (sKey_, cache) {
	let chachedContent, dynamicContentProvider;
	// preconditon: is URL with dynamic contentProvider?
	if (__.hasDynamicContentUrl(sKey_)) {
		dynamicContentProvider = __.getDynamicContentProvider(sKey_);
		// has registered content provider for this url?
		if (dynamicContentProvider) {
			return __.getContentFromProvider(dynamicContentProvider, sKey_);
		} else {
			return Promise.reject(
				new Error(
					'no registered content provider has been found for this url: ' +
						sKey_,
				),
			);
		}
	} else {
		if (STATIC_CONTENT.isStaticContentRequest(sKey_)) {
			return STATIC_CONTENT.getContent(sKey_);
		} else {
			// is allready cached?
			chachedContent = CACHE.getItem(
				'core.controller.content',
				sKey_,
				cache,
			);
			if (!exports.isCached(sKey_) || !chachedContent) {
				// new content from AEM - xhrrequest
				return __.loadRemoteContent(sKey_, cache);
			} else {
				// known content from storage/cache
				return Promise.resolve(
					CACHE.getItem('core.controller.content', sKey_, cache),
				);
			}
		}
	}
};
/**
 * Takes an array of URIs and loads them into the cache
 * @param {array} aResources_ Array of URIs
 * @returns {void}
 */
exports.fillCache = function (aResources_) {
	let _i = 0;
	for (_i; _i < aResources_.length; _i++) {
		// dump already cached ones
		if (!exports.isCached(aResources_[_i])) {
			__.aCacheURI.push({
				sKey: aResources_[_i],
			});
		}
	}
	__.processCacheURIs();
};
/**
 * Load cached items if there are no xhr-requests running
 * @returns {void}
 */
__.processCacheURIs = function () {
	let _obj;
	// noone's left
	if (__.aCacheURI.length === 0) {
		return;
	}
	// no running xhrs -  load one
	if (__.iCounterXhr < __.iParallelXHRMax) {
		_obj = __.aCacheURI.shift();
		// fire xhr
		__.loadRemoteContent(_obj.sKey, true);
	}
	// still items to load
	if (__.aCacheURI.length > 0) {
		/* jshint -W059 */
		window.setTimeout(__.processCacheURIs, 50);
	}
};
/**
 * register module to provide content (HTML-String)
 * @param {Object} contentProvider_ - object with content provider options
 * @returns {void} nothing
 */
exports.registerContentProvider = function (contentProvider_) {
	const provider = {};
	// is valid RegExp and Promise?
	if (
		!!contentProvider_ &&
		contentProvider_.regEx &&
		typeof contentProvider_.regEx.exec === 'function' &&
		contentProvider_.callbackPromise &&
		typeof contentProvider_.callbackPromise === 'function'
	) {
		provider.regEx = contentProvider_.regEx;
		provider.callbackPromise = contentProvider_.callbackPromise;
		provider.name =
			contentProvider_.name ||
			'provider#' + __.registeredContentProviders.length;
		__.registeredContentProviders.push(provider);
	} else {
		console.warn('invalid params!could not register content Provider');
	}
};
/**
 * search through all regeistered content providers for an entry
 * with a regEx matching the current url request
 * @param {string} url_ - url
 * @returns {Object} registered content provider object or null
 */
__.getDynamicContentProvider = function (url_) {
	const res = __.registeredContentProviders.filter(function (item) {
		return !!url_.match(item.regEx);
	});
	return res.length ? res[0] : null;
};
/**
 * get content from provider wrapped in a promise
 * @param {Object} contentProvider_ - content provider object
 * @param {string} url_ - url for this request
 * @returns {Promise} html string wrapped in a prmise
 */
__.getContentFromProvider = function (contentProvider_, url_) {
	const promise = contentProvider_.callbackPromise(url_);
	return typeof promise.then === 'function'
		? promise
		: Promise.reject(new Error('invalid content provider'));
};
/**
 * registers a callback for url markers.
 * @param {Function} provider -the provider callback
 * @returns {undefined}
 */
exports.registerMarkerProvider = function (provider) {
	if (
		typeof provider === 'function' &&
		__.oMarkerProvider.indexOf(provider) < 0
	) {
		__.oMarkerProvider.push(provider);
	}
};
/**
 * Adds the required XHR-marker
 * @param {string} sUrl_ Url
 * @return {Promise} url wrapped in a promise
 */
__.rewriteUrl = function (sUrl_) {
	const _ext = '.' + sUrl_.split('#')[0].split('?')[0].split('.').pop();
	const markerProviderPromises = [];
	let marker = '';
	let overwritten = false;
	__.oMarkerProvider.forEach(function (provider) {
		markerProviderPromises.push(provider(sUrl_));
	});
	return Promise.all(markerProviderPromises)
		.then(function (values) {
			values.forEach(function (_oMarker) {
				if (!!_oMarker.marker && !overwritten) {
					marker += '.' + _oMarker.marker;
					if (_oMarker.overwrites) {
						marker = '.' + _oMarker.marker;
						overwritten = true;
					}
				}
			});
			return marker;
		})
		.then(function (marker_) {
			return sUrl_.split(_ext).join(marker_ + __.sMarkerXHR + _ext);
		})
		.catch(function (err) {
			console.warn(err);
			return sUrl_;
		});
};

/**
 * XMLHTTPRequest to get content
 * @param {string} sKey_       Convention: URI
 * @param {boolean} cache Flag, if the content should be stored locally or not
 * @returns {void}
 */
__.loadRemoteContent = async function (sKey_, cache) {
	const checkResponseStatus = (response) => {
		if (response.status >= 200 && response.status < 300) {
			return response;
		} else {
			let error = new Error(response.statusText);
			error.response = response;
			throw error;
		}
	};

	const checkAndSendComponentError = (responseText) => {
		// if there is an error on the received HTML, send it to App Dynamics
		if (
			window.ADRUM &&
			window.ADRUM.command &&
			responseText.indexOf('component_error') > -1
		) {
			window.ADRUM.command('addUserData', 'component-error', 'true');
		}
	};

	try {
		const url_ = await __.rewriteUrl(sKey_);
		__.iCounterXhr += 1;
		const response = checkResponseStatus(await fetch(url_));
		const responseText = await response.text();
		checkAndSendComponentError(responseText);
		if (cache) {
			exports.setContent(sKey_, responseText);
		}
		return responseText;
	} catch (err) {
		console.warn('loadRemoteContent failed', err);
		return err;
	} finally {
		__.iCounterXhr -= 1;
	}
};

/**
 * Clears the cache (local and session storage), resets the index
 * @param {array} keysToKeep_ - lait of keys not to be eraxsed
 * @returns {void}
 */
exports.flush = function (keysToKeep_) {
	// eslint-disable-line max-statements
	let keysToKeep = keysToKeep_ || [],
		keysToRemove = [],
		key,
		i,
		decodedKey;
	if (keysToKeep.length === 0) {
		__.oIndex = {};
		CACHE.clear('core.controller.content', 'sessionStorage');
	} else {
		for (key in __.oIndex) {
			// eslint-disable-next-line no-prototype-builtins
			if (__.oIndex.hasOwnProperty(key)) {
				decodedKey = decodeURIComponent(key);
				if (__.oIndex[key] && keysToKeep.indexOf(decodedKey) === -1) {
					keysToRemove.push(decodedKey);
				}
			}
		}
		for (i in keysToRemove) {
			// eslint-disable-next-line no-prototype-builtins
			if (keysToRemove.hasOwnProperty(i)) {
				CACHE.removeItem('core.controller.content', keysToRemove[i]);
				__.updateIndex(keysToRemove[i], false);
			}
		}
	}
};
/**
 * Constructor
 * @return {void}
 */
__.initialize = function () {
	// flush storage
	exports.flush();
};
exports.debug = function () {
	return {
		cacheItems: __.aCacheURI,
		cacheLenght: __.aCacheURI.length,
	};
};

exports.initialize = function () {
	__.initialize();
};

export { exports as content };
