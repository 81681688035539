/*
 * use SCROLL.register() to get notified when something happened
 *
 *    SCROLL.register('.selector', {
 *		mode: SCROLL.MODE.INOUT | SCROLL.MODE.COVER | SCROLL.MODE.SCROLLUP | SCROLL.MODE.SCROLLDOWN
 *		notifyWhen: SCROLL.NOTIFY.ONSCROLL | SCROLL.NOTIFY.AFTERSCROLL (default: SCROLL.NOTIFY.ONSCROLL)
 *		eventName: string (eventname to be fired when change occurs - event is automatically registered)
 *	}, callback)
 *
 *    mode:
 *        INOUT
 *            gets notified, whenever the elements changes it's position to the viewport (INSIDE from/to OUTSIDE or ABOVE from/to BELOW)
 *        COVER
 *            same as INOUT, but as long as there is an registered element fully in the viewport above the changed element it is considered as BELOW
 *        SCROLLDOWN and SCROLLUP
 *            these elements always get notified when the user scrolls in the according direction (for best performance this should only be used with
 *  			AFTERSCROLL notifications)
 *            also it's best to register only one element and not a list of all elements
 *
 *    the fired event passes the following data object:
 *    {
 *			element:     the element that changed it's state
 *			eventName:   the eventname that was registered
 *			where:       where the element is now in relation to the viewport (ABOVE, IN, or BELOW)
 *			was:         where the element was before the change in relation to the viewport (ABOVE, IN, or BELOW)
 *	}
 */

// TODO: replace with interaction observer (https://developer.mozilla.org/en-US/docs/Web/API/Intersection_Observer_API)

import { appEvents } from './app-events';

const __ = {},
	exports = {
		__: __,
	};
let initialized = false,
	working = false,
	viewport = { bottom: 0, top: 0 },
	configured = [],
	registered = [[], []],
	lastWindowScrollPos = [0, 0],
	WHERE = { ABOVE: 1, BELOW: 3, IN: 2 },
	NOTIFY = { AFTERSCROLL: 1, ONSCROLL: 0 },
	MODE = { COVER: 1, INOUT: 0, SCROLLDOWN: 3, SCROLLUP: 2 },
	scrollEndTimer,
	defaults = {
		eventName: '',
		mode: MODE.INOUT,
		notifyWhen: NOTIFY.ONSCROLL,
	};

__.updateViewportData = function () {
	viewport = {
		bottom: window.innerHeight || document.documentElement.clientHeight,
		top: 0,
	};
};

__.pageLoadHandler = function (payload) {
	let i, l;
	registered = [[], []];
	__.eventBus.removeAllListeners('element.scroll');
	for (i = 0, l = configured.length; i < l; i++) {
		__.register(
			configured[i].selector,
			configured[i].options,
			configured[i].callback,
			payload.domElement,
			true,
		);
	}
};

__.initializeListeners = function () {
	if (!initialized) {
		window.addEventListener('scroll', __.scrollHandler);
		window.addEventListener('scroll', __.scrollEndHandler);
		window.addEventListener('resize', __.scrollHandler);
		window.addEventListener('resize', __.scrollEndHandler);
		window.addEventListener('resize', __.updateViewportData);
		__.updateViewportData();
		initialized = true;
	}
};

__.register = function (selector, options, callback, reInit) {
	// eslint-disable-line max-statements
	let elements,
		container,
		notifyWhen,
		eventName,
		mode,
		list,
		i,
		el,
		where,
		data,
		l;
	if (typeof reInit === 'undefined' || !reInit) {
		configured.push({
			callback: callback,
			options: options,
			selector: selector,
		});
	}
	container = document;
	elements = container.querySelectorAll(selector);
	if (elements.length) {
		__.initializeListeners();
		notifyWhen = options.notifyWhen || defaults.notifyWhen;
		eventName = options.eventName || defaults.eventName;
		mode = options.mode || defaults.mode;
		list = [];
		__.eventBus.addListener('element.scroll.' + eventName, callback);

		for (i = 0, l = elements.length; i < l; i++) {
			el = elements[i];
			where = __.getElementsState(el, mode, elements);
			data = {
				element: el,
				elements: elements,
				eventName: eventName,
				mode: mode,
				selector: selector,
				was: where,
				where: where,
			};
			__.eventBus.trigger('element.scroll.' + data.eventName, data);
			list.push(data);
		}
		registered[notifyWhen].push(list);
	}
};

__.getElementsState = function (el, mode, elements) {
	const where = __.getElementState(el);
	let i;
	if (mode === MODE.COVER && where === WHERE.IN) {
		// if we have an element with 100% coverage before the current element, we are BELOW, otherwise we are really IN
		for (i = 0; i < elements.length; i++) {
			if (elements[i] === el) {
				break;
			}
			if (__.getElementCoverage(elements[i]) === 100) {
				return WHERE.BELOW;
			}
		}
	}
	return where;
};

__.getElementState = function (el) {
	const rect = el.getBoundingClientRect();
	if (
		(rect.top >= viewport.top &&
			rect.top < viewport.bottom) /* top in viewport */ ||
		(rect.bottom >= viewport.top &&
			rect.bottom < viewport.bottom) /* bottom in viewport */ ||
		(rect.top < viewport.top &&
			rect.bottom >
				viewport.top) /* top out of viewport but bottom in or below viewport */
	) {
		return WHERE.IN;
	} else if (rect.top < viewport.top) {
		return WHERE.ABOVE;
	} else {
		return WHERE.BELOW;
	}
};

__.getElementCoverage = function (el) {
	let top, bottom, rect;
	if (__.getElementState(el) !== WHERE.IN) {
		return 0;
	}
	rect = el.getBoundingClientRect();
	if (rect.top >= viewport.top && rect.bottom <= viewport.bottom) {
		return 100;
	}
	top = rect.top;
	bottom = rect.bottom;
	if (top < 0) {
		top = 0;
	}
	if (bottom > viewport.bottom) {
		bottom = viewport.bottom;
	}
	return ((bottom - top) / (viewport.bottom - viewport.top)) * 100;
};

__.dump = function () {
	console.info(registered);
};

__.checkOnScroll = function () {
	__.checkChangedElements(NOTIFY.ONSCROLL);
};

__.checkAfterScroll = function () {
	__.checkChangedElements(NOTIFY.AFTERSCROLL);
};

__.checkChangedElements = function (notifyWhen) {
	let windowScrollPos = window.scrollY,
		i,
		j,
		l;
	working = false;
	if (registered[notifyWhen].length > 0) {
		for (i = 0, l = registered[notifyWhen].length; i < l; i++) {
			for (j in registered[notifyWhen][i]) {
				if (
					!!registered[notifyWhen][i][j] &&
					document.body.contains(registered[notifyWhen][i][j].element)
				) {
					// only check elements still in the dom
					registered[notifyWhen][i][j] = __.checkChangedElement(
						registered[notifyWhen][i][j],
						notifyWhen,
						windowScrollPos,
					);
				}
			}
		}
	}
	lastWindowScrollPos[notifyWhen] = windowScrollPos;
};

__.checkChangedElement = function (data, notifyWhen, windowScrollPos) {
	const where = __.getElementsState(data.element, data.mode, data.elements);
	data.was = data.where;
	data.where = where;
	if (data.mode === MODE.SCROLLDOWN) {
		if (windowScrollPos > lastWindowScrollPos[notifyWhen]) {
			__.eventBus.trigger('element.scroll.' + data.eventName, data);
		}
	} else if (data.mode === MODE.SCROLLUP) {
		if (windowScrollPos < lastWindowScrollPos[notifyWhen]) {
			__.eventBus.trigger('element.scroll.' + data.eventName, data);
		}
	} else if (data.was !== data.where) {
		__.eventBus.trigger('element.scroll.' + data.eventName, data);
	}
	return data;
};

__.scrollHandler = function () {
	if (!working) {
		working = true;
		window.requestAnimationFrame(__.checkOnScroll);
	}
};
__.scrollEndHandler = function () {
	clearTimeout(scrollEndTimer);
	scrollEndTimer = window.setTimeout(function () {
		window.requestAnimationFrame(__.checkAfterScroll);
	}, 250);
};

exports.initialize = function (eventEmitter) {
	__.eventBus = eventEmitter;
	__.eventBus.on(appEvents.PAGE_LOADED, __.pageLoadHandler);
};

exports.register = __.register;
exports.unregister = __.unregister;
exports.WHERE = WHERE;
exports.NOTIFY = NOTIFY;
exports.MODE = MODE;

export { exports as scroll };
