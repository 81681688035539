/* global SETUPS */
import { appEvents } from '../app-events';
import { content } from '../content';
import { dom as pageDom } from './dom';
import { dom as utilsDom } from '../../../js/utils-bundle';

const __ = {};

const exports = {
	__: __,
};

__.oDefaults = {
	initalScWrapper: '.nm-wrapper .sc-content',
	initialContentAreaSelector:
		'.nm-wrapper-content .nm-area-content:first-child',
};

__.addEvents = function () {
	__.eventBus.one(appEvents.SC_VERSION_LOADED, __.handlerSvdVersionLoaded);
	__.eventBus.on(appEvents.PAGE_LOADED, __.handlerPageLoaded);
	__.eventBus.one(appEvents.VERSION_LOAD_COMPLETE, __.handlerVersionLoaded);
};

__.preloadContent = function (domElement) {
	let preloadArr;
	// Don't preload if content data-type is 'nomo':
	// Potentially too many sub pages to store in
	// sessionStorage.
	if (
		SETUPS.get('nemo.content.preload') === 'true' ||
		SETUPS.get('stockcar.context') === 'true' ||
		pageDom.isNomo() === false
	) {
		preloadArr = pageDom.extractNavUrls(domElement);
		content.fillCache(preloadArr);
	}
};

__.handlerPageLoaded = function (payload) {
	__.preloadContent(payload.domElement);
};

__.handlerVersionLoaded = function () {
	__.preloadContent(
		utilsDom.getElement(__.oDefaults.initialContentAreaSelector),
	);
};

__.handlerSvdVersionLoaded = function () {
	__.preloadContent(utilsDom.getElement(__.oDefaults.initalScWrapper));
};

exports.initialize = function (eventEmitter) {
	__.eventBus = eventEmitter;
	__.addEvents();
};

export { exports as preload };
