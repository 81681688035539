class FootnotesHelper {
	static get FOOTNOTES_LIST_SELECTOR() {
		return '.audi-footnotes-list';
	}

	static get FOOTNOTES_LAYER_PREFIX() {
		return 'layer_footnote_';
	}

	static get FOOTNOTES_REFERENCE_SELECTOR() {
		return '.audi-j-footnote-reference';
	}

	static get FOOTNOTES_ANCHOR_TEXT_SELECTOR() {
		return '.audi-footnote-anchor__text';
	}

	static get FOOTNOTES_REFERENCE_DISABLED_SELECTOR() {
		return 'audi-footnote-reference-disabled';
	}

	static get MODAL_INNER_SELECTOR() {
		return '.modal-layer__inner';
	}

	static get MODAL_INNER_CONTENT_SELECTOR() {
		return '.modal-layer__inner-content';
	}

	static get MODAL_LAYER_CONTENT_SELECTOR() {
		return '.modal-layer__content';
	}

	static get LAYER_CONTENT_SELECTOR() {
		return '.nm-layer-inner';
	}

	/**
	 * _createFootnoteContainer - creates new footnote wrapper element (div)
	 * @returns {HTMLElement} new footnote element
	 */
	static createFootnoteContainer() {
		const footnotesElement = document.createElement('div');
		footnotesElement.classList.add('audi-footnotes');
		footnotesElement.classList.add('audi-footnotes-wrapper');
		footnotesElement.setAttribute('data-module', 'footnotes');
		footnotesElement.innerHTML =
			'<ul class="' +
			FootnotesHelper.FOOTNOTES_LIST_SELECTOR.substr(1) +
			'"></ul>';

		return footnotesElement;
	}

	/**
	 * _replaceTextWithFootnoteNumber - replace reference placeholder with number
	 * @param {HTMLElement} element_  reference
	 * @param {String} number_ - index number of referenced footnote
	 * @returns {void}
	 */
	static replaceTextWithFootnoteNumber(element_, number_) {
		if (!element_.dataset.fixedText) {
			const textContainer = element_.querySelector(
				FootnotesHelper.FOOTNOTES_ANCHOR_TEXT_SELECTOR,
			);
			textContainer.innerHTML = number_;
		}
	}

	/**
	 * extract the name of an option
	 * @param {string} rawKey_ - a raw key, with a possible "#" annotation
	 * @returns {string} - a key without a possible "#" annotation
	 */
	static extractFootnoteId(rawKey_ = '') {
		let key = rawKey_;
		const index = key.indexOf('#');

		if (index !== -1) {
			key = key.substr(index + 1);
		}

		return key;
	}

	/**
	 * removePrefixFromId
	 * @param {string} id_ - a string, with a possible "layer_footnote_" annotation
	 * @returns {string} - a string without a possible "layer_footnote_" annotation
	 */
	static removePrefixFromId(id_ = '') {
		return id_.replace(/^layer_footnote_/gi, '');
	}

	/**
	 * setReferenceState - adds or removes disabled class
	 * @param {HTMLElement} reference_ - footnote reference dom element
	 * @param {boolean} active_ - add class | remove class
	 * @returns {void}
	 */
	static setReferenceState(reference_, active_) {
		if (active_) {
			reference_.classList.remove(
				FootnotesHelper.FOOTNOTES_REFERENCE_DISABLED_SELECTOR,
			); // footnote reference does not exist in store, so no need to show a link
		} else {
			reference_.classList.add(
				FootnotesHelper.FOOTNOTES_REFERENCE_DISABLED_SELECTOR,
			); // reset
		}
	}
}

export { FootnotesHelper };
