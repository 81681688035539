/**
 * The layer element module.
 * @exports core/layer/element
 * @version 1.0a1
 */

import { dom } from '../../../js/utils-bundle';
import { jsLoader } from '../../../js/utils-bundle';

const __ = {};

const exports = {
	__: __,
};

__.BASE_CLASS = 'nm-layer-wrapper';

__.loadedScripts = [];

exports.create = function (html) {
	let container, child;
	container = document.createElement('div');
	container.className = __.BASE_CLASS;
	__.globalEval(html);
	container.innerHTML = html;

	// return child if it already has a class
	child = container.firstChild;
	if (!!child.className && child.classList.contains(__.BASE_CLASS)) {
		return child;
	}
	return container;
};

exports.update = function (el, html) {
	__.globalEval(html);
	el.innerHTML = html;
	return true;
};

// Evaluates inline javascripts in a global context
__.globalEval = function (code_) {
	let code, scripts;
	code = document.createElement('div');
	code.insertAdjacentHTML('beforeend', code_.trim());

	scripts = dom.getElementsArray('script', code);

	// no inline script tags?
	if (!scripts.length) {
		return;
	}

	window.asyncLoadingJS = false;
	__.evalScripts(scripts);
};

__.evalScripts = function (scripts) {
	// eslint-disable-line max-statements
	// FIXME: remove eval!
	let head,
		script,
		item,
		strictMode,
		indirect = eval; // eslint-disable-line no-eval

	while (scripts.length) {
		script = scripts.splice(0, 1);
		item = script[0];

		// use strcit mode?
		strictMode = item.text.indexOf('use strict') > -1;
		// If the code includes a valid, prologue position
		// strict mode pragma, execute code by injecting a
		// script tag into the document.
		if (item.getAttribute('src')) {
			__.evalSingleScript(script);
		} else if (strictMode) {
			script = document.createElement('script');
			script.text = item.text;

			// IE<9 Bugfix
			head = document.head || document.getElementsByTagName('head')[0];
			head.appendChild(script).parentNode.removeChild(script);
		} else {
			// Otherwise, avoid the DOM node creation, insertion
			// and removal by using an indirect global eval
			indirect(item);
		}
	}
};

__.evalSingleScript = function (script) {
	// eslint-disable-line max-statements
	const item = script[0];
	// use strcit mode?
	const strictMode = item.text.indexOf('use strict') > -1;

	const src = item.getAttribute('src');
	// we have to load a script, so we really need to create a new script tag for it
	// WARNING: if the loaded script calls it's own callback we need to reset window.asyncLoadingJS there to continue loading
	if (!window.asyncLoadingJS) {
		if (__.loadedScripts.indexOf(src) < 0) {
			__.loadedScripts.push(src);
			window.asyncLoadingJS = true;

			jsLoader
				.loadURL(src, item)
				.then(function () {
					window.asyncLoadingJS = false;
				})
				.catch(function () {
					window.asyncLoadingJS = false;
				});
		}
	} else {
		// WARNING: defered eval of js - if the loaded script calls it's own callback we need to reset window.asyncLoadingJS to continue loading
		setTimeout(function () {
			// eslint-disable-line no-loop-func
			__.evalSingleScript(script, strictMode);
		}, 100);
	}
};

export { exports as element };
