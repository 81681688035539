(function webpackUniversalModuleDefinition(root, factory) {
	if(typeof exports === 'object' && typeof module === 'object')
		module.exports = factory(require("microkernel"));
	else if(typeof define === 'function' && define.amd)
		define("core-application", ["microkernel"], factory);
	else if(typeof exports === 'object')
		exports["core-application"] = factory(require("microkernel"));
	else
		root["core-application"] = factory(root["microkernel"]);
})(self, (__WEBPACK_EXTERNAL_MODULE__3214__) => {
return 