/**
 * The layer animation module. This module is a submodule of the core layer
 * module and is (generally) not to be used by any other module. It can be
 * replaced per project to customize the animation, but must implement the
 * same API in order to work with the core/layer/base module.
 */

import { dom } from '../../../js/utils-bundle';

const __ = {};
const exports = {
	__: __,
};

__.oDefaults = {
	classBodyLayerOpened: 'nm-layer-opened',
	classHidden: 'nm-hidden',
	classLayerShaderVisible: 'nm-layer-shader-visible',
	classLayerVisible: 'nm-layer-visible',
	selectorLayer: '.nm-layer',
};
__.scrollPosition = 0;

/**
 * To be called before the first layer is opened.
 * Opens the shader.
 * @return {Promise} shader
 */
exports.showShader = function () {
	return new Promise(function (resolve) {
		document.body.classList.add(__.oDefaults.classBodyLayerOpened);

		__.wait(20)
			.then(function () {
				if (!__.shader) {
					__.initialize();
				}
				if (__.shader) {
					__.shader.classList.remove(__.oDefaults.classHidden);
					__.shader.classList.add(
						__.oDefaults.classLayerShaderVisible,
					);
				}
			})
			.then(function () {
				__.wait(280).then(resolve);
			});
	});
};

/**
 * wait Promise to delay function calls
 * @param {number} delay_ delay in milliseconds
 * @return {Promise} - TODO
 */
__.wait = function (delay_) {
	return new Promise(function (resolve) {
		setTimeout(resolve, delay_);
	});
};

/**
 * To be called after the last layer has been closed.
 * Closes the shader.
 * @returns {Promise} TODO
 */
exports.hideShader = function () {
	return new Promise(function (resolve) {
		document.body.classList.remove(__.oDefaults.classBodyLayerOpened);
		__.shader.classList.remove(__.oDefaults.classLayerShaderVisible);
		__.shader.classList.add(__.oDefaults.classHidden);

		resolve(__.shader);
	});
};

/**
 * findCurrentLayerOfType
 * @param {string} layerType_ ("cms","conflict","tranfer",…)
 * @return {Layer|null}  Layer instace or null
 */
__.findCurrentLayerOfType = function (layerType_) {
	const layer = dom.getElement(
		__.oDefaults.selectorLayer + '[data-layer-type="' + layerType_ + '"]',
	);
	return dom.closest(layer, '.nm-layer-wrapper');
};

/**
 * Starts an animation that opens a layer.
 * @param {string} element - The html element to be animated.
 * @param {string} layerObj - The unique id for easy DOM lookup.
 * @returns {Promise} A Promise object.
 */
exports.open = function (element, layerObj) {
	let layerEl, promise;
	// `html` `layerObj` must be given.
	if (!element || !layerObj) {
		promise = Promise.reject(
			new TypeError(
				'"animation.open": missing param ' + !element
					? '"element"'
					: '"layerObj"',
			),
		);
	} else {
		layerEl = document.body.appendChild(element);

		// If this is the first layer, call __.openLayer after exports.showShader; if not, call it
		// immediately.
		if (dom.isElement(__.oDefaults.selectorLayer)) {
			// return Promise(layer)
			promise = exports.showShader().then(function () {
				return __.openLayer(layerEl, layerObj);
			});
		} else {
			// return Promise(layer)
			promise = __.openLayer(layerEl, layerObj);
		}
	}
	return promise;
};

/**
 * internal openLayer method
 * @param {HTMLElement} layerEl_ [description]
 * @param {Layer} layer_  Layer instance
 * @return {Promise} Promise
 */
__.openLayer = function (layerEl_, layer_) {
	return new Promise(function (resolve) {
		// resolve layer before fedeIn CSS transition starts
		resolve(layer_);
		// add class, with a short delay.
		__.wait(20).then(function () {
			// layerEl_.find(__.oDefaults.selectorLayer).addClass(__.oDefaults.classLayerVisible).attr('data-layer-type', layer_.type);
			const layer = dom.getElement(__.oDefaults.selectorLayer, layerEl_);
			layer.classList.add(__.oDefaults.classLayerVisible);
			layer.setAttribute('data-layer-type', layer_.type);
		});
		// css layer transition is .5s, sremove class after little more than 500ms.
		__.wait(530).then(function () {
			document.body.classList.add('nm-' + layer_.type + '-layer-opened');
			// resolve(layer_);
		});
	});
};

/**
 * Starts an animation that closes a layer.
 * @param {string} element - The html element to be animated.
 * @param {string} layerObj - The unique id for easy DOM lookup.
 * @returns {Promise} A Promise object.
 */
exports.close = function (element, layerObj) {
	let layer, promise;
	// `html` `layerObj` must be given.
	if (!element || !layerObj) {
		promise = Promise.reject(
			new TypeError(
				'"animation.close": missing param ' + !element
					? '"element"'
					: '"layerObj"',
			),
		);
	} else {
		layer = dom.getElement(__.oDefaults.selectorLayer, element);
		if (dom.isElement(layer)) {
			layer.classList.remove(__.oDefaults.classLayerVisible);
			layer.removeAttribute('data-layer-type');
		}

		// wait for CSS fadeOut Trasition 0.5s
		promise = __.wait(500).then(function () {
			if (!dom.isElement(element)) {
				return Promise.reject('element to be removed is missing!!!');
			}
			dom.removeElement(element);

			document.body.classList.remove(
				'nm-' + layerObj.type + '-layer-opened',
			);
			return Promise.resolve(layerObj);
		});
	}
	return promise;
};

exports.toggleShader = function () {
	if (__.isShaderNotNeeded()) {
		exports.hideShader();
	} else {
		console.info('Shader remains visible');
	}
};

/**
 * check if the shader has to be removed
 * @return {boolean} TODO
 */
__.isShaderNotNeeded = function () {
	return __.getVisibleLayers().length === 0;
};

/**
 * check if the current layer is the last one opened
 * @return {boolean} TODO
 */
__.isLastVisibleLayer = function () {
	return __.getVisibleLayers().length === 1;
};

/**
 * get the number of layer visible (opacity=1)
 * @return {boolean} TODO
 */
__.getVisibleLayers = function () {
	return dom.getElementsArray(
		__.oDefaults.selectorLayer + '.' + __.oDefaults.classLayerVisible,
	);
};

/**
 * Starts an animation that replaces a layer.
 * @param {string} element - The html element to be animated.
 * @param {string} layerObj - The unique id for easy DOM lookup.
 * @returns {Promise} TODO.
 */
exports.replace = function (element, layerObj) {
	let layerType, oldLayer, layerEl, promise;
	// `html` `layerObj` must be given.
	if (!element || !layerObj) {
		promise = Promise.reject(
			new TypeError(
				'"animation.replace": missing param ' + !element
					? '"element"'
					: '"layerObj"',
			),
		);
	} else {
		promise = new Promise(function (resolve, reject) {
			layerType = layerObj.type;
			oldLayer = __.findCurrentLayerOfType(layerType);

			if (!dom.isElement(oldLayer)) {
				reject(new TypeError('noLayer of type ' + layerObj.type));
			} else {
				// fadeOut old layer from DOM
				oldLayer.classList.remove(__.oDefaults.classLayerVisible);
				layerEl = document.body.appendChild(element);
				// add class, with a short delay (longer for replace because we run a fadeout on the old layer).
				__.wait(500).then(function () {
					const layer = dom.getElement(
						__.oDefaults.selectorLayer,
						layerEl,
					);
					layer.classList.add(__.oDefaults.classLayerVisible);
					layer.setAttribute('data-layer-type', layerObj.type);
				});
				// css layer transition is .5s, so resolve after a little more than 500ms.
				document.body.classList.add(
					'nm-' + layerObj.type + '-layer-opened',
				);
				__.wait(530).then(function () {
					document.body.removeChild(oldLayer);
					resolve(layerObj);
				});
			}
		});
	}
	return promise;
};

__.initialize = function () {
	if (!dom.isElement('.nm-layer-shader')) {
		document.body.insertAdjacentHTML(
			'beforeend',
			'<div class="nm-layer-shader nm-hidden"></div>',
		);
		__.shader = dom.getElement('.nm-layer-shader');
	}
};

__.initialize();

export { exports as animation };
