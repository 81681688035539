import { dom } from '../../../js/utils-bundle';
import { polling } from '../../../js/utils-bundle';
import { appEvents as EVENTS } from '../app-events';
import { FootnotesHelper } from './footnotes-helper';
import FootnotesRenderer from './footnotes-renderer';
import FootnotesSmoothScroll from './footnotes-smooth-scroll';
import { FootnotesStore } from './footnotes-store';
import { dynamicSmoothScroll } from '../dynamic-smooth-scroll';
import { stateRegistry } from 'microkernel';

class FootnotesEngine {
	static get defaults() {
		return {
			FOOTNOTES_WRAPPER_SELECTOR: '.audi-footnotes-wrapper',
		};
	}

	/**
	 * subscribes to footnote store and initializes engine
	 * @returns {void}
	 */
	constructor() {
		this.footnotesSmoothscroll = new FootnotesSmoothScroll();

		this._bindEventHandler();
		this._addEvents();

		FootnotesStore.addFootnotesStore();
		stateRegistry.subscribeToStore(
			'dbadFootnotesStore',
			this._handleStoreUpdate,
		);
	}

	/**
	 * _bindEventHandler - bind event handler to this
	 * @returns {void}
	 */
	_bindEventHandler() {
		this._handleStoreUpdate = this._handleStoreUpdate.bind(this);
		this._handleContentUpdate = this._handleContentUpdate.bind(this);

		this._handleFootnotesUpdate = dom.debounce(
			this._handleFootnotesUpdate,
			250,
		);
	}

	/**
	 * _addEvents - adds events to modelfinder filter elements
	 * @returns {void}
	 */
	_addEvents() {
		document.addEventListener('PAGE_READY', this._handleContentUpdate);
		document.addEventListener('LAYER_LOADED', this._handleContentUpdate);
		document.addEventListener(
			EVENTS.CONTENT_RENDERED,
			this._handleContentUpdate,
		);
	}

	/**
	 * _handleStoreUpdate - save footnotestore state and prepare for rendering
	 * @param {Object} state_ - current state of footnotes store
	 * @returns {void}
	 */
	_handleStoreUpdate(state_ = {}) {
		this.footnotesInStore = state_;

		if (Object.keys(this.footnotesInStore).length) {
			this._handleFootnotesUpdate();
		}
	}

	/**
	 * _handleContentUpdate - handle content update
	 * // we need the timeout because of timing issues with modal layer logic, that can't be fixed
	 * @param {Event} event_ - update event
	 * @returns {void}
	 */
	async _handleContentUpdate(event_) {
		let condition = this._handleContentUpdateDefaultCondition;

		if (
			event_.type === 'LAYER_LOADED' ||
			event_.type === EVENTS.CONTENT_RENDERED
		) {
			if (document.querySelector('.modal-layer--open')) {
				condition = this._handleContentUpdateModalLayerCondition;
			} else if (document.querySelector('.nm-layer-opened')) {
				condition = this._handleContentUpdateDefaultLayerCondition;
			}
		}

		try {
			await polling.wait(condition, 1000);

			this._handleFootnotesUpdate();
		} catch (error_) {
			console.warn('could not handle footnote content', error_);
		}
	}

	/**
	 * _handleContentUpdateDefaultCondition - default condition (function only required for testing)
	 * @returns {boolean} true
	 */
	_handleContentUpdateDefaultCondition() {
		return true;
	}

	/**
	 * _handleContentUpdateModalLayerCondition - condition function for MODAL LAYER Case (function only required for testing)
	 * @returns {boolean} true if MODAL LAYER case | false
	 */
	_handleContentUpdateModalLayerCondition() {
		return !!document.querySelector(FootnotesHelper.MODAL_INNER_SELECTOR);
	}

	/**
	 * _handleContentUpdateDefaultLayerCondition - condition function for default LAYER Case (function only required for testing)
	 * @returns {boolean} true if default LAYER case | false
	 */
	_handleContentUpdateDefaultLayerCondition() {
		return !!document.querySelector(FootnotesHelper.LAYER_CONTENT_SELECTOR);
	}

	/**
	 * _handleFootnotesUpdate - handle foootnotes update
	 * @returns {void}
	 */
	_handleFootnotesUpdate() {
		const domContext = dynamicSmoothScroll.getScrollContext();

		const isLayer = this._isContextOfTypeLayer(domContext);

		const footnoteReferences = [
			...this._findFootnoteReferences(domContext),
		];

		if (footnoteReferences.length > 0) {
			const footnoteReferenceIds = this._prepareFootnotesForRendering(
				footnoteReferences,
				domContext,
				isLayer,
			);

			this._processFootnoteReferences(
				footnoteReferences,
				footnoteReferenceIds,
				isLayer,
			);
		} else {
			this._removeFootnoteWrapper(domContext);
		}
	}

	/**
	 * _findFootnoteReferences- parses domContext for existing footnotes and initializes footnote update if any were found
	 * @param {HTMLElement} domContext_ - dom element containing possible footnote references
	 * @returns {void}
	 */
	_findFootnoteReferences(domContext_) {
		return domContext_.querySelectorAll(
			FootnotesHelper.FOOTNOTES_REFERENCE_SELECTOR,
		);
	}

	/**
	 * _processFootnotes - parses footnote reference ids and prepares footnotes for rendering
	 * @param {array} footnoteReferences_ - array of dom elements containing footnote references
	 * @param {HTMLElement} domContext_ - dom element containing footnote references
	 * @param {boolean} isLayer_ - is context layer or not
	 * @returns {Array} footnoteReferenceIds
	 */
	_prepareFootnotesForRendering(
		footnoteReferences_ = [],
		domContext_,
		isLayer_ = false,
	) {
		const footnoteReferenceIds =
			this._collectFootnoteIdsFromReferences(footnoteReferences_);
		const footnoteWrapper = this._getFootnoteWrapper(domContext_);

		if (footnoteReferenceIds.length > 0) {
			const footnotesData = this._getFootnotesByIdFromStore(
				footnoteReferenceIds,
				isLayer_,
			);

			FootnotesRenderer.renderFootnotes(footnotesData, footnoteWrapper);
		}

		return footnoteReferenceIds;
	}

	/**
	 * _processFootnoteReferences - parses footnote references and sets number / disables if no footnote exists and adds layer prefix
	 * @param {array} footnoteReferences_ - array of dom elements containing footnote references
	 * @param {array} footnoteReferenceIds_ - array of active footnote ids
	 * @param {boolean} isLayer_ - is context layer or not
	 * @returns {void}
	 */
	_processFootnoteReferences(
		footnoteReferences_ = [],
		footnoteReferenceIds_ = [],
		isLayer_ = false,
	) {
		footnoteReferences_.forEach((element) => {
			if (isLayer_) {
				this._addPrefixToReferenceForLayer(element);
			}

			const footnoteId = FootnotesHelper.extractFootnoteId(
				element.getAttribute('href'),
			);
			let index = footnoteReferenceIds_.indexOf(
				FootnotesHelper.removePrefixFromId(footnoteId),
			);

			// test for keys with and without layer prefix because of the case - layer is already patched and then there's a content rendered event
			if (index === -1) {
				index = footnoteReferenceIds_.indexOf(
					FootnotesHelper.FOOTNOTES_LAYER_PREFIX + footnoteId,
				);
			}

			const setActive = index !== -1;
			FootnotesHelper.setReferenceState(element, setActive);

			FootnotesHelper.replaceTextWithFootnoteNumber(element, index + 1);
		});
	}

	/**
	 * _collectFootnoteIdsFromReferences - find footnote ids from references
	 * @param {array} footnoteReferences_ - array of dom elements containing footnote references
	 * @returns {array} array of footnote ids
	 */
	_collectFootnoteIdsFromReferences(footnoteReferences_ = []) {
		let footnoteReferenceIds = [];

		footnoteReferences_.forEach((element) => {
			const footnoteId = FootnotesHelper.removePrefixFromId(
				FootnotesHelper.extractFootnoteId(element.getAttribute('href')),
			);
			const index = footnoteReferenceIds.indexOf(footnoteId);

			if (index === -1 && this._isValidFootnoteReference(footnoteId)) {
				footnoteReferenceIds.push(footnoteId);
			}
		});

		return footnoteReferenceIds;
	}

	/**
	 * _isValidFootnoteReference - checks if footnote reference exists in store
	 * @param {String} id_  footnote id
	 * @returns {boolean} true if footnote id exists in store | false
	 */
	_isValidFootnoteReference(id_) {
		const finalId = FootnotesHelper.removePrefixFromId(id_);

		return !!this.footnotesInStore[finalId];
	}

	/**
	 * _getFootnoteWrapper - returns footnote wrapper element
	 * @param {HTMLElement} domContext_ - context in which footnote wrapper element should exist
	 * @returns {HTMLElement} footnote wrapper in dom
	 */
	_getFootnoteWrapper(domContext_) {
		let context = domContext_;

		if (!this._hasContextFootnoteWrapper(domContext_)) {
			const footnotesElement = FootnotesHelper.createFootnoteContainer();

			if (context.querySelector(FootnotesHelper.MODAL_INNER_SELECTOR)) {
				context = context.querySelector(
					FootnotesHelper.MODAL_LAYER_CONTENT_SELECTOR,
				);
			} else if (
				context.querySelector(FootnotesHelper.LAYER_CONTENT_SELECTOR)
			) {
				context = context.querySelector(
					FootnotesHelper.LAYER_CONTENT_SELECTOR,
				);
			} else if (context.querySelector('.nm-footer')) {
				context = context.querySelector('.nm-footer');
			}

			context.appendChild(footnotesElement);
		}

		return context.querySelector(
			FootnotesEngine.defaults.FOOTNOTES_WRAPPER_SELECTOR +
				' ' +
				FootnotesHelper.FOOTNOTES_LIST_SELECTOR,
		);
	}

	/**
	 * _hasContextFootnoteWrapper - checks if footnote wrapper exists
	 * @param {HTMLElement} domContext_ - dom context to search wrapper in
	 * @returns {boolean} true if context ist layer | false
	 */
	_hasContextFootnoteWrapper(domContext_) {
		return !!domContext_.querySelector(
			FootnotesEngine.defaults.FOOTNOTES_WRAPPER_SELECTOR,
		);
	}

	/**
	 * _removeFootnoteWrapper - removes footnote wrapper from context
	 * @param {HTMLElement} domContext_ - dom context to search wrapper in
	 * @returns {void}
	 */
	_removeFootnoteWrapper(domContext_) {
		if (this._hasContextFootnoteWrapper(domContext_)) {
			const wrapper = domContext_.querySelector(
				FootnotesEngine.defaults.FOOTNOTES_WRAPPER_SELECTOR,
			);
			wrapper.parentElement.removeChild(wrapper);
		}
	}

	/**
	 * _isContextOfTypeLayer - checks if context is layer
	 * @param {HTMLElement} domContext_ - dom element to check for context type
	 * @returns {boolean} true if context ist layer | false
	 */
	_isContextOfTypeLayer(domContext_) {
		const context = domContext_;
		return !!(
			context.querySelector(FootnotesHelper.MODAL_INNER_SELECTOR) ||
			context.querySelector(FootnotesHelper.LAYER_CONTENT_SELECTOR)
		);
	}

	/**
	 * _getFootnotesByIdFromStore - filters footnotes by active ids
	 * @param {Array} footnoteReferenceIds_ - Array of Ids
	 * @param {boolean} isLayer_ - is context layer or not
	 * @returns {Array} array of active footnote dom elements
	 */
	_getFootnotesByIdFromStore(footnoteReferenceIds_ = [], isLayer_ = false) {
		let footnotes = new Map();
		let index = 1;

		footnoteReferenceIds_.forEach((id) => {
			const idWithoutPrefix = FootnotesHelper.removePrefixFromId(id);

			if (this.footnotesInStore[idWithoutPrefix]) {
				const footnoteEntry = this.footnotesInStore[idWithoutPrefix];
				const footnote = document.createElement('div');
				footnote.innerHTML = footnoteEntry;

				footnote.querySelector(
					FootnotesHelper.FOOTNOTES_ANCHOR_TEXT_SELECTOR,
				).innerHTML = index;

				if (isLayer_) {
					footnote.firstChild.setAttribute(
						'id',
						FootnotesHelper.FOOTNOTES_LAYER_PREFIX +
							idWithoutPrefix,
					);
					footnotes.set(
						FootnotesHelper.FOOTNOTES_LAYER_PREFIX +
							idWithoutPrefix,
						footnote.firstChild,
					);
				} else {
					footnotes.set(idWithoutPrefix, footnote.firstChild);
				}

				index++;
			}
		});

		return [...footnotes.values()];
	}

	/**
	 * _patchFootnoteIdsForLayer - adds prefix to footnote references and footnotes for layer context
	 * @param {HTMLElement} reference_ - footnote reference dom element
	 * @returns {void}
	 */
	_addPrefixToReferenceForLayer(reference_) {
		const href = reference_.getAttribute('href');
		const id = FootnotesHelper.extractFootnoteId(href);
		const idWithoutPrefix = FootnotesHelper.removePrefixFromId(id);
		const footnoteName =
			FootnotesHelper.FOOTNOTES_LAYER_PREFIX + idWithoutPrefix;

		reference_.setAttribute('href', '#' + footnoteName);
	}
}

export { FootnotesEngine };
