/* eslint-disable max-len */
const exports = `
	<div class="nm-layer-wrapper nm-layer-wrapper-dialogue">
		<div class="nm-layer nm-layer-dialogue nm-error-layer nm-layer-visible">
			<div class="nm-layer-inner">
				<div class="nm-layer-dialogue-content clearfix">
					<h1 class="nm-el-hdl nm-el-hdl-02 nm-at-hdl-b">{{=it.errorHeadline}}</h1>
					<p class="nm-error nm-error-layer-message nm-el-pg nm-el-pg-03 nm-at-pg-indicator" style="float:none;" data-defaultErrorMessage="{{=window.i18n['error-layer.unknown-error']}}">{{=it.errorMessage}}</p>
					<div class="nm-layer-btn-ok"><a class="nm-el-btn nm-el-btn-01 nm-j-ok-btn" href="#">{{=window.i18n['ok-label']}}</a></div>
				</div>
			</div>
		</div>
	</div>
`;
/* eslint-enable max-len */
export { exports as errorTemplate };
