/**
 * The Page Type module.
 */

import { appEvents } from '../app-events';
import { dom as domUtils } from '../../../js/utils-bundle';

const __ = {},
	exports = {
		__: __,
	};

__.oDefaults = {
	selectorAreaContent: '.nm-area-content',
	selectorBody: 'body',
};
__.eventBus = null;

__.addEvents = function () {
	__.eventBus.on(appEvents.PAGE_READY, __.updatePageType);
};

__.removeEvents = function () {
	__.eventBus.off(appEvents.PAGE_READY, __.updatePageType);
};

__.updatePageType = function () {
	const area = domUtils.getElement(__.oDefaults.selectorAreaContent);
	const body = domUtils.getElement(__.oDefaults.selectorBody);
	const pageType =
		area.getAttribute('data-page-type') ||
		(!area.length && body.getAttribute('page-type')) ||
		'default';
	body.setAttribute('page-type', pageType);
};

exports.initialize = function (eventEmitter) {
	__.eventBus = eventEmitter;
	__.addEvents();
};

export { exports as type };
